.error-section {
    overflow: hidden;
    width: 100%;
    .site-path-indicator {
      @include up(md) {
        margin: 43px 0 27px;
      }
  
      @include down(md) {
        margin: 43px 21px 27px;
      }
    }
  
    .error {
        min-height: 40vh;
        text-align: center;
        padding: 7% 2%;
        max-width: 90%;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include up(md) {
            margin: 0px 50px;
        }
        @include down(md) {
            margin: 20px;
        }
    }
}