/* specialized, but still frequently used */
@mixin tableWithBlueHeader() {
  width: 100%;

  > tbody {
    > tr.head {
      background-color: #0080ff;
      color: #fff;

      > th {
        padding-top: 8px;
        padding-bottom: 0;
        padding-left: 14px;
        padding-right: 14px;

        @include font($teko, 21px, 500);
      }
    }

    > tr.gap {
      height: 21px;

      border-bottom: 1px solid #7bc1f6;
    }

    > tr.body {
      border-bottom: 1px solid #7bc1f6;

      @include font($robotoCondensed, 15px, 400);

      > td {
        padding-top: 8px;
        padding-bottom: 10px;
        padding-left: 14px;
        padding-right: 14px;

        color: #191919;
      }

      &.highlighted {
        font-weight: 700;
      }
    }
  }
}

@mixin tablesWithBlueHeaderSm($headerWidth: 72px) {
  @include flexColumn();

  width: 100%;

  .table-wrapper {
    width: 100%;

    padding: 24px 18px;

    &:nth-child(odd) {
      background-color: #fff;
    }

    &:nth-child(even) {
      background-color: #f5f5f5;
    }

    table {
      width: 100%;

      tr {
        border-top: 1px solid #b6dbff;
        border-bottom: 1px solid #b6dbff;

        th {
          width: $headerWidth;
          
          padding-top: 10px;
          padding-bottom: 7px;
          padding-right: 24px;

          @include fontAlt($teko, 22px, 24px, 400);

          color: #0080ff;
        }

        td {
          padding: 10px 0 7px;

          @include fontAlt($robotoCondensed, 17px, 21px, 400);

          color: #050505;
        }
      }
    }
  }
}

@mixin genericHeadToHeadTableLg() {
  @include tableWithBlueHeader();

  tr.head {
    th {
      &.video {
        width: 1px;
        white-space: nowrap;
      }
    }
  }

  tr.body {
    td {
      &.video {
        padding: 0;
        text-align: center;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 100vh;
        max-height: 40px;
        min-width: 80px;
        .play {
          @include flexRow($inline: true);
          @include horizontalMargins(9px / 2);
          width: 20px;
          height: 20px;
          border-radius: 2px;
          background-color: #0080ff;
          color: #fff;
          &:before {
            content: '';
            width: 20px;
            height: 20px;
            background-image: url('../img/hollow-triangle-right.svg');
            background-repeat: no-repeat;
            background-position: center;
            margin-left: 3px;
          }
        }
      }
    }
  }

  @include down(lg) {
    display: none;
  }
}

@mixin genericHeadToHeadTablesSm() {
  @include flexColumn();
  @include verticalMargins(35px / 2);

  width: 100%;

  .opponent-wrapper {
    width: 100%;

    .opponent-header {
      @include flexColumn();
      @include fontAlt($teko, 28px, 25px, 400);

      height: 45px;

      margin-bottom: 10px;

      color: #fff;
      background-color: #0080ff;

      span {
        margin-top: 6px;
      }
    }

    .opponent-data {
      width: 100%;

      tr.head {
        th {
          padding: 5px 2px;

          &:first-of-type {
            padding: 5px 2px 5px 0;
          }

          &:last-of-type {
            padding: 5px 0 5px 2px;
          }

          border-bottom: 1px solid #b6dbff;

          @include fontAlt($teko, 21px, 24px, 400);

          color: #0080ff;

          &.video {
            width: 1px;
            white-space: nowrap;
            text-align: center;
          }
        }
      }

      tr.body {
        td {
          padding: 10px 2px;

          &:first-of-type {
            padding: 10px 2px 10px 0;
          }

          &:last-of-type {
            padding: 10px 0 10px 2px;
          }

          border-bottom: 1px solid #b6dbff;

          @include fontAlt($robotoCondensed, 16px, 21px, 400);

          color: #050505;

          &.video {
            padding: 0;
            min-width: 80px;
            text-align: center;
            .play {
              @include flexRow($inline: true);
              @include horizontalMargins(3px / 2);
              width: 20px;
              height: 20px;
              border-radius: 2px;
              background-color: #0080ff;
              color: #fff;
              &:before {
                content: '';
                width: 20px;
                height: 20px;
                background-image: url('../img/hollow-triangle-right.svg');
                background-repeat: no-repeat;
                background-position: center;
                margin-left: 3px;
              }
            }
          }
        }
      }
    }
  }

  @include up(lg) {
    display: none;
  }
}

@mixin genericResultsComparisonMd() {
  @include tableWithBlueHeader();

  > tbody {
    > tr {
      th, td {
        &.opp1, &.opp2 {
          width: 1px;
          white-space: nowrap;

          text-align: center;
        }
      }
    }

    > tr.head {
      th {
        &.video {
          width: 1px;
          white-space: nowrap;
        }
      }
    }

    > tr.body {
      td {
        &.better, &.worse {
          padding-top: 0;
          padding-bottom: 0;

          > div {
            @include flexRow($inline: true);
            @include horizontalMargins(9px / 2);

            width: 69px;
            height: 30px;

            border-radius: 2px;
          }
        }

        &.better {
          > div {
            background-color: #0080ff;
            color: #fff;
          }
        }

        &.worse {
          > div {
            border: 1px solid #0080ff;
          }
        }
      }
    }
  }

  @include down(md) {
    display: none;
  }
}

@mixin genericMedalsOverviewMd() {
  @include tableWithBlueHeader();

  @include down(md) {
    display: none;
  }

  > tbody {
    > tr.head {
      height: 50px;

      .medal {
        width: .1%;
        white-space: nowrap;

        padding-top: 0px;
        padding-right: 23px;
        padding-left: 81px;

        text-align: center;

        .medal-circle {
          width: 38px;
          height: 38px;

          border-radius: 50%;

          background-color: #fff;

          img {
            height: 25px;
          }
        }
      }

      .controls {
        width: 1px;
        white-space: nowrap;
      }
    }

    > tr.body {
      background-color: #fff;

      .medal {
        padding-right: 23px;
        padding-left: 81px;

        text-align: center;
      }

      .controls {
        span {
          &:after {
            content: "";
            background-image: url('../img/arrow_black.svg');
            width: 7px;
            height: 10px;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: 50%;
            z-index: 1;
            margin-right: 10px;
            margin-top: -1px;
            transition: all 0.4s ease;
            transform: rotate(90deg);
          }
          &.active {
            &:after {
                transform: rotate(-90deg);
            }
          }
        }
      }
    }

    > tr.entries-row {
      &.collapsed {
        .entries-cell {
          border-bottom-width: 0px;

          .entries-wrapper {
            margin-bottom: 0;

            transition-timing-function: ease-in;
          }
        }
      }

      .entries-cell {
        padding: 0;
        overflow: hidden;
    
        border-bottom: 1px solid #b6dbff;
    
        .entries-wrapper {
          padding: 16px 18px;
          margin-bottom: 10px;
      
          background-color: #f5f5f5;

          transition-property: margin-top, margin-bottom;
          transition-duration: .3s;
          transition-timing-function: ease-out;
      
          .entries {
            width: 100%;
      
            tr.head {
              th {
                @include fontAlt($teko, 16px, 35px, 500, uppercase);
      
                color: #a0a0a0;
              }
            }
      
            tr.head + tr.body {
              td {
                border-top: 1px solid #dbdbdb;
              }
            }
      
            tr.body {
              td {
                padding: 10px 0 8px;
                border-bottom: 1px solid #dbdbdb;
      
                @include fontAlt($robotoCondensed, 17px, 21px, 400);
      
                color: #050505;
              }
            }
          }
        }
      }
    }
  }
}

@mixin genericMedalsOverviewSm() {
  @include up(md) {
    display: none;
  }

  .event {
    .event-header {
      @include flexRow(space-between);
      @include horizontalMargins(24px / 2);

      width: 100%;
      min-height: 49px;

      padding: 7px 18px;

      background-color: #0080ff;
      color: #fff;

      .event-label {
        @include fontAlt($teko, 25px, 27px, 400);
      }

      .event-name {
        text-align: right;

        @include fontAlt($robotoCondensed, 19px, 24px, 400);
      }
    }

    .event-table {
      width: 100%;

      &.collapsed {
        tr.head {
          th {
            &.controls {
              img {
                opacity: 1;
              }
            }
          }
        }

        tr.entries-row {
          .entries-cell {
            .entries-wrapper {
              margin-bottom: 0;

              transition-timing-function: ease-in;
            }
          }
        }
      }

      &:not(.collapsed) {
        > tbody {
          > tr.body {
            border-bottom-width: 0px;
          }
        }
      }

      > tbody {
        > tr.head {
          height: 45px;

          border-bottom: 1px solid #b6dbff;

          th {
            text-align: center;

            &.medal {
              img {
                height: 25px
              }
            }

            &.controls {
              width: 1px;
              white-space: nowrap;

              padding: 0 17px;

              img {
                padding: 5px 3px;

                cursor: pointer;

                opacity: 0;

                transition-property: opacity;
                transition-duration: .3s;
              }
            }
          }
        }

        > tr.body {
          height: 45px;

          border-bottom: 1px solid #b6dbff;

          td {
            @include fontAlt($robotoCondensed, 19px, 27px, 400);

            text-align: center;

            color: #191919;
          }
        }

        > tr.entries-row {
          .entries-cell {
            padding: 0;
            overflow: hidden;

            .entries-wrapper {
              @include flexColumn();
              @include verticalMargins(8px / 2);

              width: 100%;

              margin-bottom: 24px;

              transition-property: margin-top, margin-bottom;
              transition-duration: .3s;
              transition-timing-function: ease-out;

              .entry {
                width: 100%;

                tr {
                  @include fontAlt($robotoCondensed, 17px, 21px, 400);

                  height: 40px;

                  border-top: 1px solid #b6dbff;

                  color: #050505;

                  td {
                    padding: 0 15px 0 12px;
                  }

                  &.event-name-row {
                    background-color: #f5f5f5;
                  }

                  &.info-row {
                    border-bottom: 1px solid #b6dbff;

                    .date, .category {
                      width: 40%;
                      text-align: left;
                    }

                    .result {
                      text-align: right;

                      @include fontAlt($teko, 22px, 24px, 400);

                      color: #0080ff;

                      span {
                        display: inline-block;
                        transform: translateY(2px);
                      }
                    }
                  }
                }
              }

              .footer-bar {
                @include flexRow(flex-end);

                width: 100%;
                height: 49px;

                padding: 0 20px;

                background: #0080ff;
                background: -moz-linear-gradient(180deg, #0080ff 0%, #0059b2 100%);
                background: -webkit-linear-gradient(180deg, #0080ff 0%, #0059b2 100%);
                background: linear-gradient(180deg, #0080ff 0%, #0059b2 100%);

                img {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

@mixin genericNestedTableMd() {
  @include tableWithBlueHeader();

  @include down(md) {
    display: none;
  }

  > tbody {
    > tr.head {
      height: 50px;

      .controls {
        width: 1px;
        white-space: nowrap;
      }
    }

    > tr.body {
      background-color: #fff;

      .controls {
          span {
            &:after {
              content: "";
              background-image: url('../img/arrow_black.svg');
              width: 7px;
              height: 10px;
              display: inline-block;
              background-repeat: no-repeat;
              background-position: 50%;
              z-index: 1;
              margin-right: 10px;
              margin-top: -1px;
              transition: all 0.4s ease;
              transform: rotate(90deg);
            }
            &.active {
              &:after {
                  transform: rotate(-90deg);
              }
            }
          }
      }
    }

    > tr.entries-row {
      &.collapsed {
        .entries-cell {
          border-bottom-width: 0px;

          .entries-wrapper {
            margin-bottom: 0;

            transition-timing-function: ease-in;
          }
        }
      }

      .entries-cell {
        padding: 0;
        overflow: hidden;
    
        border-bottom: 1px solid #b6dbff;
    
        .entries-wrapper {
          padding: 16px 18px;
          margin-bottom: 10px;
      
          background-color: #f5f5f5;

          transition-property: margin-top, margin-bottom;
          transition-duration: .3s;
          transition-timing-function: ease-out;
      
          .entries {
            width: 100%;
      
            tr.head {
              th {
                @include fontAlt($teko, 16px, 35px, 500, uppercase);
      
                color: #a0a0a0;
              }
            }
      
            tr.head + tr.body {
              td {
                border-top: 1px solid #dbdbdb;
              }
            }
      
            tr.body {
              td {
                padding: 10px 0 8px;
                border-bottom: 1px solid #dbdbdb;
      
                @include fontAlt($robotoCondensed, 17px, 21px, 400);
      
                color: #050505;
              }
            }
          }
        }
      }
    }
  }
}

@mixin genericNestedTableSm() {
  @include up(md) {
    display: none;
  }

  .element {
    .element-header {
      @include flexRow(space-between);
      @include horizontalMargins(24px / 2);

      width: 100%;
      min-height: 49px;

      padding: 7px 18px;

      background-color: #0080ff;
      color: #fff;

      .element-label {
        @include fontAlt($teko, 25px, 27px, 400);
      }

      .element-name {
        text-align: right;

        @include fontAlt($robotoCondensed, 19px, 24px, 400);
      }
    }

    .element-table {
      width: 100%;

      &.collapsed {
        tr {
          th, td {
            &.controls {
              img {
                opacity: 1;
              }
            }
          }
        }

        tr.entries-row {
          .entries-cell {
            .entries-wrapper {
              margin-bottom: 0;

              transition-timing-function: ease-in;
            }
          }
        }
      }

      &:not(.collapsed) {
        > tbody {
          > tr.body {
            border-bottom-width: 0px;
          }
        }
      }

      > tbody {
        > tr {
          > th, > td {
            &.controls {
              width: 1px;
              white-space: nowrap;

              padding: 0 17px;

              img {
                padding: 5px 3px;

                cursor: pointer;

                opacity: 0;

                transition-property: opacity;
                transition-duration: .3s;
              }
            }
          }
        }

        > tr.head {
          height: 45px;

          border-bottom: 1px solid #b6dbff;

          th {
            @include fontAlt($teko, 21px, 24px, 400);

            color: #0080ff;
          }
        }

        > tr.body {
          height: 45px;

          border-bottom: 1px solid #b6dbff;

          td {
            @include fontAlt($robotoCondensed, 16px, 21px, 400);

            color: #050505;
          }
        }

        > tr.entries-row {
          .entries-cell {
            padding: 0;
            overflow: hidden;

            .entries-wrapper {
              @include flexColumn();
              @include verticalMargins(8px / 2);

              width: 100%;

              margin-bottom: 24px;

              transition-property: margin-top, margin-bottom;
              transition-duration: .3s;
              transition-timing-function: ease-out;

              .entries {
                width: 100%;

                background-color: #f5f5f5;

                tr {
                  height: 40px;

                  border-bottom: 1px solid #b6dbff;

                  th, td {
                    padding: 4px 12px;
                  }

                  &.entries-head {
                    border-top: 1px solid #b6dbff;

                    th {
                      @include fontAlt($teko, 22px, 24px, 400);
  
                      color: #0080ff;
                    }
                  }

                  &.entry-body {
                    td {
                      @include fontAlt($robotoCondensed, 17px, 21px, 400);
  
                      color: #050505;
                    }
                  }
                }
              }

              .footer-bar {
                @include flexRow(flex-end);

                width: 100%;
                height: 49px;

                padding: 0 20px;

                background: #0080ff;
                background: -moz-linear-gradient(180deg, #0080ff 0%, #0059b2 100%);
                background: -webkit-linear-gradient(180deg, #0080ff 0%, #0059b2 100%);
                background: linear-gradient(180deg, #0080ff 0%, #0059b2 100%);

                img {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

@mixin resultsTableMd() {
  @include genericNestedTableMd();

  > tbody {
    > tr.entries-row {
      > .entries-cell {
        .entries-wrapper {
          .entries {
            tr {
              th, td {
                &.noc, &.round, &.result {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

@mixin resultsTableSm() {
  @include genericNestedTableSm();

  .element-table {
    > tbody {
      th, td {
        padding-left: 2px;
        padding-right: 2px;

        &.date, &.result {
          text-align: left;
        }

        &.type, &.category {
          text-align: center;
        }

        &.date {
          padding-left: 18px;
        }
      }

      > tr.entries-row {
        th, td {
          &.noc, &.round, &.result {
            width: 1px;
            white-space: nowrap;

            text-align: center;
          }
        }
      }
    }
  }
}