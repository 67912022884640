.site-path-indicator {
  @include flexColumn(center, flex-start);
  @include verticalMargins(8px / 2);

  .first-line {
    @include flexRow(flex-start, $wrap: wrap);
    @include horizontalMargins(7px / 2);

    .home {
      @include font($teko, 17px, 500, uppercase);
      color: #282828;
  
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  
    .fill-arrow {
      width: 5px;
      height: 10px;
  
      background-color: #0080ff;
  
      clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
      transform: translate(1px, -1px);
    }
  
    .path-segment {
      @include font($teko, 17px, 400);

      white-space: nowrap;
  
      color: #282828;
      text-decoration: none;
  
      &:last-child {
        color: #0080ff;
      }
  
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .second-line {
    @include flexRow(flex-start);
    @include horizontalMargins(7px / 2);

    .back-arrow {
      width: 5px;
      height: 10px;
  
      background-color: #505050;
  
      clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
      transform: translate(1px, -1px);
    }

    .back-text {
      @include fontAlt($teko, 22px, 31px, 500);
    }
  }
}