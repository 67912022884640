header {
  padding-top: 25px;
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  transition: all 0.4s ease;
  @include down(xl) {
    padding-top: 60px;
    padding-left: 0;
    max-height: 60px;
    overflow: hidden;
    background: transparent linear-gradient(0deg, #E3E3E3 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px -9px rgba(0,0,0,0.75);
    height: 100%;
  }
  &.active {
    @include down(xl) {
      max-height: 1000px;
    }
  }
  .header-nav {
    display: flex;
    flex-wrap: wrap;
    background: transparent linear-gradient(0deg, #E3E3E3 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    max-width: 1200px;
    margin: auto;
    box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
    transition: all 0.4s ease;
    @include down(xl) {
      height: 100%;
      box-shadow: none;
      flex-direction: column;
    }
    .header-button {
      @include up(xl) {
        display: none;
      }
      width: 40px;
      height: 18px;
      position: absolute;
      right: 15px;
      top: 20px;
      .wrap {
        height: 100%;
        width: 100%;
        position: relative;
        span {
          position: absolute;
          display: block;
          transition: all 0.5s ease;
          background: #0080ff;
          width: 90%;
          height: 4px;
          right: 0;
          &:nth-child(1) {
            top: 0;
            width: 75%;
          }
          &:nth-child(2) {
            bottom: 7px;
          }
          &:nth-child(3) {
            bottom: 0;
            width: 60%;
          }
        }
      }
      &.active {
        .wrap {
          span {
            &:nth-child(1) {
              top: 7px;
              width: 68%;
              left: 7px;
              transform: rotate(-45deg);
              background: #0080ff;
            }
            &:nth-child(2) {
              opacity: 0;
            }
            &:nth-child(3) {
              bottom: 7px;
              right: 7px;
              width: 68%;
              transform: rotate(45deg);
              background: #0080ff;
            }
          }
        }
      }
    }
    .header-logo-wrapper {
      transition: all 0.4s ease;
      align-items: center;
      justify-content: center;
      display: flex;
      -webkit-clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
      clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
      background: #0080ff;
      @include down(xl) {
        position: absolute;
        top: 0px;
        height: 60px;
        -webkit-clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
        width: 180px;
      }
      @include up(xs) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      @include up(xl) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      @include up(hd) {
        flex: 0 0 18%;
        max-width: 18%;
      }
      a {
        text-decoration: none;
        span {
          color: white;
          font-size: 18px;
          font-style: italic;
          &:first-child {
            font-weight: 700;
          }
        }
      }
    }
    .header-main-menu {
      list-style: none;
      padding: 10px 0 10px 0;
      transition: all 0.4s ease;
      margin: 0;
      position: relative;
      display: flex;
      min-height: 70px;
      @include up(xs) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      @include up(xl) {
        flex: 0 0 41%;
        max-width: 41%;
        padding: 10px 0 10px 0;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: normal;
      }
      @include up(fhd) {
        text-align: center;
        padding: 10px 0 10px 0;
      }
      @include down(xl) {
        max-height: max-content;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
      }
      @include down(xl) {
        flex: inherit;
        flex-flow: column;
      }
      .menu-item {
        &.mobile-margin-top {
          @include down(xl) {
            margin-top: 0;
          }
        }
        &.item-arrow {
          &.language-item {
            position: relative;
            z-index: 100;
            &:hover {
              .sub-menu {
                display: block;
                visibility: visible;
                opacity: 1;
                flex-flow: column;
                justify-content: center;
              }
            }
            a {
              background-image: inherit;
              &:after {
                left: 30%;
              }
            }
            .sub-menu {
              min-width: inherit;
              left: 0;
              right: 0;
              top: 19px;
              background-image: inherit;
              position: absolute;
              display: block;
              @include down(lg) {
                top: 35px;
              }
              li {
                display: block;
                a {
                  display: block;
                }
              }
            }
          }
          a {
            &:after {
              content: "";
              position: absolute;
              transition: all 0.4s ease;
              left: 36%;
              top: 75%;
              transform: rotate(0deg) translate(50%, 0);
              width: 0;
              height: 0;
              display: inline-block;
              border-right: solid 6px transparent;
              border-top: solid 6px #0080ff;
              border-left: solid 6px transparent;
              border-bottom: solid 6px transparent;
              @include down(sm) {
                border: 0;
              }
            }
            &:hover {
              &:after {
                border-top-color: white;
              }
            }
          }
          &.active {
            @include down(sm) {
              margin: 10px auto -20px auto;
            }
          }
        }
      }
      .active {
        .sub-menu {
          display: block;
          visibility: visible;
          opacity: 1;
          flex-flow: column;
          justify-content: center;
        }
      }
      .sub-menu {
        background-image: linear-gradient(#017ef9, #0169d0);
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 60px;
        z-index: 2;
        width: 100%;
        min-width: 1200px;
        text-align: left;
        padding: 12px 0px;
        height: 50px;
        margin: auto;
        opacity: 0;
        visibility: hidden;
        animation: fade 0.4s;
        @include down(xl) {
          height: auto;
          min-width: 200px;
          position: initial;
          margin-left: 0;
          background-color: #0080ff;
          background-image: inherit;
        }
        @include down(sm) {
          background-image: inherit;
        }
        li {
          @include down(sm) {
            margin: 5px auto 5px auto!important;
          }
          a {
            background-image: linear-gradient(#017ef9, #0169d0);
            &:after {
              content: inherit!important;
            }
            @include down(xl) {
              background-image: inherit;
            }
            @include down (sm) {
              background-image: inherit;
              color: #0080ff!important;
              background-color: inherit!important;
            }
          }
        }
      }
      li {
        &.search {
          background-color: transparent;
          display: inline-block;
          color: #bbb;
          padding: 10px 15px;
          padding-left: 5px;
          padding-right: 5px;
          width: 100%;
          position: absolute;
          height: 45px;
          cursor: pointer;
          &:after {
            content: '';
            width: 25px;
            height: 25px;
            background-image: url('../img/search.svg');
            background-repeat: no-repeat;
            position: absolute;
            top: 18px;
            right: 28px;
            z-index: 101;
          }
          input {
            width: 80px;
            border: 1px solid #0080ff;
            transition: all 0.4s ease;
            position: absolute;
            right: 0;
            height: 40px;
            margin-right: 30px;
            padding-left: 5px;
            margin-top: -5px;
            &:focus {
              outline: inherit;
              width: 94%;
              z-index: 100;
              margin-left: 10px;
            }
          }
          @include down(xl) {
            display: none;
          }
        }
        &:not(.search) {
          z-index: 99;
          @include up(xl) {
            margin: 0;
          }
          @include up(fhd) {
            margin: 0;
          }
          @include up(xs) {
            display: block;
          }
          @include down(xl) {
            max-width: max-content;
            margin: 5px auto 5px auto;
            text-align: center;
            padding-top: 5px;
          }
          @include up(xl) {
            display: inline-block;
          }
          @include down(sm) {
            margin: 10px auto 10px auto;
          }
          a {
            text-decoration: none;
            position: relative;
            font-family: $montserrat;
            text-transform: uppercase;
            font-size: 15px;
            color: #0080ff;
            padding: 10px 18px;
            font-weight: 700;
            @include up(xl) {
              font-size: 12px;
            }
          }
          &.active, &:hover {
            a {
              color: white!important;
              background-color: #0080ff;
            }
          }
        }
      }
    }
  }
  &.scrolled {
    background-image: linear-gradient(#ffffff, #ebebeb);
    box-shadow: 0px 0px 20px -6px rgba(0,0,0,0.75);
    padding-top: 0px;
    @include down(xl) {
      padding-top: 60px;
    }
    &.active {
      @include down(xl) {
        max-height: 1000px;
      }
    }
    .header-nav {
      background-image: linear-gradient(#ffffff, #ebebeb);
      box-shadow: none;
      .header-logo-wrapper {
        img {
          @include up(hd) {
            transform: scale(.8);
          }
          @include up(fhd) {
            transform: scale(.7);
          }
        }
      }
      .header-main-menu {
        @include up(xl) {
          padding-top: 4px;
        }
        @include up(fhd) {
          padding: 10px 0 10px 0;
          text-align: right; 
        }
        li {
          a {
            color: #0080ff;;
          }
          &.active {
            .sub-menu {
              &:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                right: 100%;
                background-image: linear-gradient(#017ef9, #0169d0);
                transition: all 0.4s ease;
              }
              &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                background-image: linear-gradient(#017ef9, #0169d0);
                left: 100%;
                width: 100%;
              }
            }
            &.language-item {
              .sub-menu {
                &:before {
                  background-image: inherit;
                }
                &:after {
                  background-image: inherit;
                }
              }
            }
            a {
              background-color: #0080ff;
            }
          }
        }
      }
    }
  }
}

header {
  .header-social-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    min-width: 100px;
    z-index: 100;
    position: relative;
    @include down(xl) {
      justify-content: center;
    }
    a {
      background-color: #0080ff;
      padding: 0 12px;
      align-items: center;
      display: flex;
      min-height: 35px;
      position: absolute;
      left: 5px;
      top: 9px;
      &.instagram-icon {
        left: 55px;
        @include down(xl) {
          left: inherit;
        }
      }
      @include down(xl) {
        margin: 0 6px;
        position: inherit;
        left: inherit;
        top: inherit;
      }
      img {
        transition: all 0.4s ease;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}