.event-details-section {
  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .event-details-content {
    @include down(md) {
      padding: 0 17px;
    }

    margin-bottom: 60px;

    .event-details-header {
      @include flexColumn();
      width: 100%;

      padding: 35px 44px;
      margin-bottom: 54px;

      background-color: #f5f5f5;

      .header-upper {
        @include up(md) {
          @include flexRow(space-between, stretch);

          margin-bottom: 26px;
        }
        
        @include down(md) {
          @include flexColumn(center, stretch);
          @include verticalMargins(20px / 2);

          margin-bottom: 36px;
        }

        width: 100%;

        .header-info {
          .header-info-row {
            .label {
              @include font($robotoCondensed, 19px, 400);

              color: #acacac;
            }
            
            .data {
              padding: 0 15px;

              @include font($robotoCondensed, 25px, 400);

              color: #333333;
            }
          }
        }

        .header-actions {
          @include flexColumn(flex-start);

          .donate {
            @include largeBlueButton();
          }
        }
      }

      .header-lower {
        @include up(md) {
          @include flexRow(space-between);
          @include horizontalMargins(16px / 2);
        }

        @include down(md) {
          @include flexColumn();
          @include verticalMargins(16px / 2);
        }

        width: 100%;

        .comparable-label {
          flex-shrink: 0;
        }

        .comparable {
          @include genericSelectGroup();
        }
      }
    }

    .event-details-tabs {
      @include horizontalTabList();
      @include down (lg) {
        flex-flow: column;
      }
    }


    .event-details-tab-content {
      width: 100%;

      margin-top: 60px;

      @include down(md) {
        padding: 0 17px;
      }
    }
  }
}