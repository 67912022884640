.events-judo-competitions-section {
  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .events-judo-competitions-content {
    @include flexColumn();

    width: 100%;

    @include down(md) {
      padding: 0 17px;
    }

    > .title {
      width: 100%;
      margin-bottom: 15px;

      @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);

      color: #333333;
    }

    .judo-competitions-list {
      @include responsiveGridCol4();

      width: 100%;

      margin-bottom: 60px;

      .competitions-list-item {
        @include flexColumn(flex-end);

        @include up(xl) {
          height: 144px;
        }

        @include betw(md, xl) {
          height: 123px;
        }

        @include down(md) {
          height: 102px;
        }

        padding: 21px 5px 0;

        background: #0080ff;
        background: -moz-linear-gradient(180deg, #0080ff 0%, #155faa 100%);
        background: -webkit-linear-gradient(180deg, #0080ff 0%, #155faa 100%);
        background: linear-gradient(180deg, #0080ff 0%, #155faa 100%);

        color: #fff;

        .short-code {
          margin-bottom: 9px;

          text-align: center;

          @include fontAlt($teko, 30px, 27px, 700, uppercase);
        }

        .name {
          margin-bottom: 21px;

          text-align: center;

          @include fontAlt($robotoCondensed, 15px, 18px, 400);
        }
      }
    }
  }
}