@mixin searchResults() {
  .results-title {
    margin-bottom: 15px;

    @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);

    color: #333333;

    @include down(lg) {
      margin-left: 28px;
      margin-right: 28px;
    }
  }

  .results-table-lg {
    @include up(lg) {
      @include tableWithBlueHeader();
    }

    @include down(lg) {
      display: none;
    }
  }

  .results-tables-sm {
    @include up(lg) {
      display: none;
    }

    @include down(lg) {
      @include tablesWithBlueHeaderSm();
    }
  }
}

@mixin searchForm() {
  @include flexRow();

  width: 100%;

  margin-bottom: 48px;

  background-color: #f5f5f5;

  .cta-tab {
    flex-shrink: 0;

    @include flexColumn();

    width: 53px;
    height: 100%;

    background: #0064c7;
    background: -moz-linear-gradient(180deg, #0064c7 0%, #004386 100%);
    background: -webkit-linear-gradient(180deg, #0064c7 0%, #004386 100%);
    background: linear-gradient(180deg, #0064c7 0%, #004386 100%);

    .cta-text {
      margin-right: -5px;

      writing-mode: vertical-lr;
      transform-origin: center;
      transform: rotate(180deg);

      @include font($teko, 38px, 500, uppercase);
      color: #c6e3ff;
    }
  }

  .form-body {
    @include flexColumn(space-between);

    width: 100%;
    height: 100%;

    padding: 0 25px;

    .inputs {
      @include flexColumn();
      @include verticalMargins(25px / 2);

      width: 100%;
      height: 100%;

      .inputs-row {
        @include flexRow(space-between);
        @include horizontalMargins(40px / 2);

        width: 100%;

        .input-group {
          @include flexColumn(center, flex-start);

          label {
            @include font($robotoCondensed, 15px, 400);

            margin-bottom: 4px;
          }

          input {
            @include genericInput();
          }

          select,
          .style-select {
            @include genericSelect();
          }
        }

        .input-group-set {
          @include flexRow();
          @include horizontalMargins(12px / 2);

          width: 100%;
        }
      }
    }

    .actions {
      flex-shrink: 0;
      
      @include flexRow();

      width: 100%;

      margin-bottom: 35px;

      @include down(lg) {
        justify-content: flex-start;
        margin-bottom: 15px;
        margin-left: 27px;
      }

      .find-button {
        .hajime-button {
          font-size: 24px;
          @include largeBlueButton();
        }
      }
    }
  }
}

.search-events-section {
  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .search-events-content {
    margin-bottom: 275px;

    .search-form-wrapper {
      @include searchForm();

      @include up(lg) {
        height: 310px;
      }

      @include down(lg) {
        height: 516px;
      }
    }

    .search-results {
      @include searchResults();

      @include up(lg) {
        .results-table-lg {
          .nr {
            width: .1%;
            white-space: nowrap;
          }

          .date {
            text-align: right;
          }
        }
      }
    }
  }
}

.search-judoka-section {
  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .search-judoka-content {
    margin-bottom: 275px;

    .search-form-wrapper {
      @include searchForm();

      @include up(lg) {
        height: 310px;
      }

      @include down(lg) {
        height: 590px;
      }
    }

    .search-results {
      @include searchResults();
    }

    @include up(lg) {
      .results-table-lg {
        .head {
          th {
            font-weight: 400!important;
          }
        }
        .birth-date {
          width: .1%;
          white-space: nowrap;

          text-align: right;
        }
      }
    }
  }
}