.profile-tab-content.videos {
  .videos-list {
    @include responsiveGridCol3();

    width: 100%;

    .video-list-item {
      @include genericVideoListItem();
    }
  }
}