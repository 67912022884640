.privacy-section {
    overflow: hidden;
    width: 100%;
    .site-path-indicator {
      @include up(md) {
        margin: 43px 0 27px;
      }
  
      @include down(md) {
        margin: 43px 21px 27px;
      }
    }
    .privacy {
      @include up(md) {
        margin: 0px 50px;
      }
      @include down(md) {
        margin: 20px;
      }
  
      .privacy-header {
        width: 100%;
        margin: 48px 0 38px;
        padding: 10px;
        background-color: #0080ff;
        color: white;
        @include down(lg) {
          text-align: center;
        }
  
        span {
          @include font($robotoCondensed, 30px, 700, uppercase);
        }
      }
      .privacy-content {
        margin-bottom: 30px;
    
        @include verticalMargins(32px / 2);
    
        .content-line {
          color: #191919;
    
          &.title {
            @include fontAlt($robotoCondensed, 18px, 20px, 700);
          }
    
          &:not(.title) {
            @include fontAlt($robotoCondensed, 15px, 20px, 400);
            a {
                color: #0080ff;
            }
          }
        }
      }
      .privacy-footer {
          span {
              font-style: italic;
          }
      }
    }
}