.home-head-to-head-section {
  .hth-content-wrapper {
    
    .hth-content {
      @include flexRow();

      background: #fff;
      background: -moz-linear-gradient(180deg, #fff 0%, #e6e6e6 100%);
      background: -webkit-linear-gradient(180deg, #fff 0%, #e6e6e6 100%);
      background: linear-gradient(180deg, #fff 0%, #e6e6e6 100%);

      .cta-tab {
        flex-shrink: 0;

        @include flexColumn();

        width: 53px;

        @include up(hd) {
          height: 263px;
        }
        @include down(hd) {
          height: 305px;
        }

        background: #0064c7;
        background: -moz-linear-gradient(180deg, #0064c7 0%, #004386 100%);
        background: -webkit-linear-gradient(180deg, #0064c7 0%, #004386 100%);
        background: linear-gradient(180deg, #0064c7 0%, #004386 100%);

        .cta-text {
          margin-right: -5px;

          writing-mode: vertical-lr;
          transform-origin: center;
          transform: rotate(180deg);

          @include font($teko, 38px, 500, uppercase);
          color: #c6e3ff;
        }
      }

      .fighters-wrapper-hd {
        @include flexColumn();
        @include down(hd) {
          display: none;
        }

        width: 100%;

        margin-top: 14px;
        
        .fighters-hd {
          @include flexRow();

          width: 100%;
          height: 100%;

          .fighter-1 {
            @include flexColumn(flex-end);

            width: 100%;

            position: relative;

            .name {
              @include flexRow(flex-end);

              width: 100%;
              padding-right: 264px;
              
              span {
                display: inline-block;
                @include fontAlt($teko, 30px, 42px, 500);
              }
            }

            .info {
              @include flexRow(flex-end);
              @include horizontalPaddings(16px / 2);

              width: 100%;
              height: 70px;

              margin-bottom: 6px;

              > * {
                border-top: 1px solid #dbdbdb;
                border-bottom: 1px solid #dbdbdb;
              }

              .suit {
                height: 100%;

                img {
                  width: 64px;
                  height: 64px;
                }
              }

              .bio {
                height: 100%;

                padding-top: 2px;

                .bio-row {
                  @include flexRow(space-between);

                  .field {
                    margin-right: 24px;

                    @include fontAlt($teko, 20px, 34px, 500, uppercase);

                    color: #a0a0a0;
                  }

                  .value {
                    text-align: right;

                    @include fontAlt($teko, 20px, 34px, 400, uppercase);

                    color: #153977;
                  }
                }
              }

              .flag {
                @include flexColumn();

                height: 100%;

                img {
                  width: 78px;
                  height: 52px;
                }
              }

              .extension {
                width: 60px;
                height: 100%;

                margin-right: 204px;
              }
            }

            .vote {
              @include flexRow(flex-end);

              width: 100%;
              height: 40px;

              margin-top: 6px;

              .vote-button {
                @include flexRow();
                @include horizontalMargins(6px / 2);

                width: 126px;
                height: 100%;

                background-color: #0080ff;
                color: #fff;

                clip-path: polygon(12px 0%, calc(100% - 12px) 0%, 100% 50%, calc(100% - 12px) 100%, 12px 100%, 0% 50%);
                transform: translateX(7px);

                img {
                  display: inline-block;
                }

                span {
                  display: inline-block;

                  margin-bottom: -5px;

                  @include fontAlt($teko, 28px, 35px, 500, uppercase);
                }
              }

              .grey-arrow {
                width: 271px;
                height: 100%;

                background-color: #c1c1c1;

                clip-path: polygon(0% 0%, calc(100% - 12px) 0%, 100% 50%, calc(100% - 12px) 100%, 0% 100%, 12px 50%);
              }
            }

            .portrait {
              height: 100%;
              max-width: 180px;
              max-height: 164px;
              position: absolute;
              bottom: 0px;
              right: 45px;
              @include down(md) {
                max-width: none;
                max-height: none;
              }
            }
          }

          .vs {
            align-self: flex-end;

            padding: 0 11px;

            @include fontAlt($teko, 32px, 38px, 500, uppercase);

            color: #0080ff;
          }

          .fighter-2 {
            @include flexColumn(flex-end);

            width: 100%;

            position: relative;

            .name {
              @include flexRow(flex-start);

              width: 100%;
              padding-left: 264px;

              span {
                display: inline-block;
                @include fontAlt($teko, 30px, 42px, 500);
              }
            }

            .info {
              @include flexRow(flex-start);
              @include horizontalPaddings(16px / 2);

              width: 100%;
              height: 70px;

              margin-bottom: 6px;

              > * {
                border-top: 1px solid #dbdbdb;
                border-bottom: 1px solid #dbdbdb;
              }

              .suit {
                height: 100%;

                img {
                  width: 64px;
                  height: 64px;
                }
              }

              .bio {
                height: 100%;

                padding-top: 2px;

                .bio-row {
                  @include flexRow(space-between);

                  .field {
                    margin-right: 24px;

                    @include fontAlt($teko, 20px, 34px, 500, uppercase);

                    color: #a0a0a0;
                  }

                  .value {
                    text-align: right;

                    @include fontAlt($teko, 20px, 34px, 400, uppercase);

                    color: #153977;
                  }
                }
              }

              .flag {
                @include flexColumn();

                height: 100%;

                img {
                  width: 78px;
                  height: 52px;
                }
              }

              .extension {
                width: 60px;
                height: 100%;
                
                margin-left: 204px;
              }
            }

            .vote {
              @include flexRow(flex-start);

              width: 100%;
              height: 40px;

              margin-top: 6px;

              .vote-button {
                @include flexRow();
                @include horizontalMargins(6px / 2);

                width: 126px;
                height: 100%;

                background-color: #0080ff;
                color: #fff;

                clip-path: polygon(12px 0%, calc(100% - 12px) 0%, 100% 50%, calc(100% - 12px) 100%, 12px 100%, 0% 50%);
                transform: translateX(-7px);

                img {
                  display: inline-block;
                }

                span {
                  display: inline-block;

                  margin-bottom: -5px;

                  @include fontAlt($teko, 28px, 35px, 500, uppercase);
                }
              }

              .grey-arrow {
                width: 271px;
                height: 100%;

                background-color: #c1c1c1;

                clip-path: polygon(12px 0%, 100% 0%, calc(100% - 12px) 50%, 100% 100%, 12px 100%, 0% 50%);
              }
            }

            .portrait {
              height: 100%;

              position: absolute;
              bottom: 0px;
              left: 45px;
            }
          }
        }

        .actions-hd {
          @include flexRow();
          @include horizontalMargins(6px / 2);

          height: 24px;
          margin-top: 24px;

          .action {
            @include flexColumn();

            height: 100%;
            a {
              @include font($roboto, 12px, 700, uppercase);

              color: #fff;
              span {
                display: inline-block;
              }
            }

            &.left, &.right {
              width: 147px;

              background-color: #0058af;
            }

            &.left {
              clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 10px 100%, 0% 50%);
            }

            &.right {
              clip-path: polygon(0% 0%, calc(100% - 10px) 0%, 100% 50%, calc(100% - 10px) 100%, 0% 100%);
            }

            &.main {
              width: 192px;

              background-color: #0080ff;
            }
          }
        }
      }

      .fighters-sm {
        @include up(hd) {
          display: none;
        }

        @include flexColumn();
        @include verticalMargins(20px / 2);

        width: 100%;
        margin-left: 19px;
        margin-bottom: 19px;

        .fighter {
          @include flexColumn();

          width: 100%;

          position: relative;

          .info {
            @include flexRow(space-between);

            width: 100%;
            padding: 3px 9px 4px 84px;

            .name {
              display: inline-block;

              margin-top: -4px;

              @include fontAlt($teko, 24px, 38px, 500);
            }

            .flag {
              width: 52px;
              height: 35px;
            }
          }

          .actions {
            @include flexRow(flex-start);

            width: 100%;
            height: 40px;

            .grey-arrow {
              height: 100%;

              background-color: #c1c1c1;

              &.left {
                flex-shrink: 0;

                width: 108px;

                transform: translateX(14px);
                clip-path: polygon(0% 0%, 100% 0%, calc(100% - 12px) 50%, 100% 100%, 0% 100%);
              }

              &.right {
                flex-grow: 30;

                clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 12px 50%);
              }
            }

            .vote-button {
              flex-shrink: 0;
              @include flexRow();
              @include horizontalMargins(6px / 2);

              width: 126px;
              height: 100%;

              background-color: #0080ff;
              color: #fff;

              transform: translateX(7px);
              clip-path: polygon(12px 0%, calc(100% - 12px) 0%, 100% 50%, calc(100% - 12px) 100%, 12px 100%, 0% 50%);

              img {
                display: inline-block;
              }

              span {
                display: inline-block;

                margin-bottom: -5px;

                @include fontAlt($teko, 28px, 35px, 500, uppercase);
              }
            }
          }

          .portrait {
            width: 74px;
            height: 82px;

            position: absolute;
            bottom: 0px;
            left: 0px;

            object-fit: cover;
            object-position: 50% 50%;
          }

          &.fighter-1 {

          }

          &.fighter-2 {

          }
        }

        .vs {
          @include flexRow(flex-start);
          @include horizontalMargins(6px / 2);

          width: 100%;

          .vs-arrow {
            width: 17px;
            height: 21px;

            background-color: #c1c1c1;

            &.left {
              clip-path: polygon(7px 0%, 100% 0%, 100% 100%, 7px 100%, 0% 50%);
            }

            &.right {
              clip-path: polygon(0% 0%, calc(100% - 7px) 0%, 100% 50%, calc(100% - 7px) 100%, 0% 100%);
            }
          }

          .vs-text {
            @include fontAlt($teko, 30px, 38px, 500, uppercase);

            margin-bottom: -5px;

            color: #0080ff;
          }
        }
      }
    }

    .hth-content-secondary-sm {
      @include up(hd) {
        display: none;
      }

      .actions-sm {
        @include flexRow();
        @include horizontalMargins(8px / 2);

        height: 46px;
        margin-top: 18px;

        .action {
          @include flexColumn();

          height: 100%;
          a {
            @include fontAlt($roboto, 14px, 17px, 700, uppercase);
            color: #fff;
          }

          &.left, &.right {
            width: 96px;
            background-color: #0058af;
          }

          &.left {
            padding-right: 5px;
            text-align: right;
            clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 10px 100%, 0% 50%);
          }

          &.right {
            padding-left: 5px;
            text-align: left;
            clip-path: polygon(0% 0%, calc(100% - 10px) 0%, 100% 50%, calc(100% - 10px) 100%, 0% 100%);
          }

          &.main {
            width: 128px;

            padding: 2px 5px;
            text-align: center;

            background-color: #0080ff;
          }
        }
      }
    }

    .visible-sm {
      @include up(hd) {
        display: none;
      }
    }

    .visible-hd {
      @include down(hd) {
        display: none;
      }
    }
  }
}