.home-news-list-section {
  overflow: hidden;

  width: 100%;

  .home-news-list-content {
    @include up(md) {
      margin: 0px 50px;
    }
    @include down(md) {
      margin: 0px;
    }

    .home-news-list-header {
      width: 100%;
      margin: 48px 0 38px;

      @include down(lg) {
        text-align: center;
      }

      span {
        @include font($robotoCondensed, 30px, 700, uppercase);
      }
    }

    .home-news-list-body {
      display: grid;
      gap: 40px;

      width: 100%;
      margin-bottom: 53px;

      @include up(xl) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @include betw(lg, xl) {
        grid-template-columns: 1fr 1fr;
      }
      @include down(lg) {
        grid-template-columns: 1fr;
      }

      .news-wrapper {
        transition: all ease 0.3s;
        -webkit-backface-visibility: hidden;
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        image-rendering: auto;
        .news-header {
          @include flexRow(flex-start);
          height: 35px;
          .category-wrapper {
            @include flexRow($inline: true);
            height: 100%;
            padding: 0px 32px 0px 20px;
            background-color: #0080FF;
            color: #fff;
            clip-path: polygon(0% 0%, calc(100% - 12px) 0%, 100% 100%, 0% 100%);
            .category {
              padding-top: 5px;
              @include font($teko, 18px, 500, uppercase);
            }
          }
        }

        .img-wrapper {
          @include flexColumn();
          position: relative;
          overflow: hidden;
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
          img {
            width: 100%;
            max-width: 575px;
            max-height: 100%;
            height: 370px;
            object-fit: cover;
            transition: all ease 0.3s;
            @include betw(sm, lg) {
              max-width: none;
              max-height: none;
            }
          }

          .content-wrapper {
            @include flexColumn(flex-end);
            height: 100%;
            width: 100%;
            padding: 20px;
            position: absolute;

            .title {
              width: 100%;
              cursor: default;
              @include fontAlt($robotoCondensed, 30px, 34px, 700, uppercase);

              color: #fff;
              background-color: rgba(0, 128, 255, 0.4);
              padding: 6px 12px;
              @include down(sm) {
                font-size: 24px;
              }
            }

            .bottom-line {
              @include flexRow(flex-start);
              @include horizontalMargins(17px / 2);

              width: 100%;

              padding: 12px 0;

              border-top: 1px solid #fff;

              .date {
                @include font($robotoCondensed, 15px, 400);
                cursor: default;
                color: #fff;
              }
  
              .read-more {
                @include smallArrowButton();
                padding: 3px 8px 3px 5px;
              }
            }
          }
        }
      }
    }
  }
}