.profile-tab-content.betting {
  .title {
    margin-top: 48px;

    &.last-contests {
      @include down(lg) {
        display: none;
      }
    }
  }

  .match-balance {
    @include tableWithBlueHeader();
  }

  .last-contests {
    table.hth-lg {
      @include genericHeadToHeadTableLg();
    }

    /*.hth-sm {
      @include genericHeadToHeadTablesSm();
    }*/
  }

  .last-results {
    .nested-md {
      @include resultsTableMd();
    }
  
    .nested-sm {
      @include resultsTableSm();
    }
  }
}