footer {
  position: relative;

  &.has-search-bar-up-sm {
    @include up(sm) {
      margin-bottom: 78px;
    }
  }
  &:not(.has-search-bar-up-sm) {
    @include up(xl) { 
      .search-bar {
        display: none;
      }
    }
  }
  
  @include down(sm) {
    margin-bottom: 73px;
  }

  background: transparent linear-gradient(180deg, #0080FF 0%, #0059B2 100%) 0% 0% no-repeat padding-box;
  @include up(xs) {
    padding: 35px 0 18px 0;
  }
  @include up(xl) {
    padding: 35px 0 45px 0;
  }
  .container {
    @include betw(xl, fhd) {
      max-width: 95vw;
    }
    max-width: 1650px;
    @include up(fhd) {

      max-width: 86vw;
    }
    @media (min-width: 2040px) {
      max-width: 88vw;
    }
    .footer-logo-wrapper {
      padding-bottom: 30px;
      a {
        text-decoration: none;
        span {
          color: white;
          font-size: 18px;
          font-style: italic;
          &:first-child {
            font-weight: 700;
          }
        }
      }
      @include down(xl) {
        text-align: center;
      }
      @include down(sm) {
        padding-bottom: 0;
      }
    }
    .footer-text {
      @include up(xs) {
        text-align: center;
      }
      @include up(xl) {
        text-align: left;
      }
      p {
        font-family: $montserrat;
        font-size: 15px;
        color: white;
        font-weight: 500;
        cursor: default;
        @include down(xl) {
          padding-top: 10px;
          font-size: 13px;
        }
      }
    }
    .footer-menu-wrapper {
      display: flex;
      justify-content: flex-end;
      @include down(xl) {
        justify-content: center;
      }
      ul {
        @include betw(sm, fhd) {
          margin: 0;
        }
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          @include up(xs) {
            margin-bottom: 5px;
            margin-right: 6px;
          }
          @include up(xl) {
            margin-bottom: 13px;
            margin-right: 6px;
          }
          &:last-child {
            a {
              &:after {
                display: none;
              }
            }
          }
          a {
            @include font($teko, 18px, 700, uppercase);

            color: white;
            text-decoration: none;
            position: relative;
            transition: all .4s ease;
            @include down(sm) {
              font-size: 16px;
            }
            &:after {
              content: "";
              height: 20px;
              width: 2px;
              display: inline-block;
              background-color: #ffffff;
              position: absolute;
              top: 0px;
              right: -6px;
            }
            &:before {
              content: '';
              left: 51%;
              right: 51%;
              height: 1px;
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 0;
              background-color: white;
              transition: all 0.4s ease;
            }
            &:hover {
              color: white;
              &:before {
                left: 0;
                right: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
    .social-col {
      display: flex;
      justify-content: flex-end;
      @include down(xl) {
        justify-content: center;
      }
      .social-wrapper {
        display: flex;
        justify-content: center;
        margin-right: 3px;
        @include down(sm) {
          order: -1;
        }
        a {
          @include up(xs) {
            margin: 10px 25px;
          }
          @include up(xl) {
            margin: 0 0 0 50px;
          }
        }
        img {
          display: block;
          transition: all 0.4s ease;
          max-width: 21px;
          &:hover {
            opacity: .7;
          }
        }
      }
    }
  }
}