@import './core/variables';
@import './core/mixins';
@import './core/layouting';
@import './core/typography';
@import './core/common';
@import './layout/misc';
@import './layout/container';
@import './layout/footer';
@import './layout/header';
@import './layout/inputs';
@import './layout/list-items';
@import './layout/tables';
@import './partials/contact-us';
@import './partials/countries';
@import './partials/country-details';
@import './partials/event-details';
@import './partials/event-details-age-statistics';
@import './partials/event-details-head-to-head';
@import './partials/event-details-medal-table';
@import './partials/event-details-news';
@import './partials/event-details-photos';
@import './partials/event-details-results';
@import './partials/event-details-startlist';
@import './partials/event-details-videos';
@import './partials/events-judo-competitions';
@import './partials/events-judo-results';
@import './partials/events-judoka-birthday';
@import './partials/generic-page-header';
@import './partials/generic-sidebar';
@import './partials/highlighted-tags';
@import './partials/home-featured';
@import './partials/home-galleries-list';
@import './partials/home-head-to-head';
@import './partials/home-news-list';
@import './partials/home-top-section';
@import './partials/judo-statistics';
@import './partials/judo-stats-generator';
@import './partials/judoka-legends';
@import './partials/judoka-profile';
@import './partials/judoka-profile-betting';
@import './partials/judoka-profile-career';
@import './partials/judoka-profile-head-to-head';
@import './partials/judoka-profile-news';
@import './partials/judoka-profile-photos';
@import './partials/judoka-profile-results';
@import './partials/judoka-profile-videos';
@import './partials/news-details';
@import './partials/news-list';
@import './partials/search-bar';
@import './partials/search-events-judoka';
@import './partials/site-path-indicator';
@import './partials/videos-list';
@import './partials/video-details';
@import './partials/privacy';
@import './partials/copyright';
@import './partials/about';
@import './partials/search';
@import './partials/error';
@import '../../node_modules/@szelep/styleselect/scss/styleselect.scss';

html, body {
	margin: 0;
	padding: 0;
	background: white;
	min-height: 100%;
}

body {
	position: relative;
	@include down(xl) {
		padding-top: 60px;
	}
}

.style-select {
	display: flex;
	align-items: center;
	font-weight: 400!important;
	width: 100%;
	font-family: "Roboto Condensed";
    font-size: 15px;
    line-height: 18px;
	border: 1px solid #b5b5b5;

	.ss-dropdown {
		top: 38px;
		z-index: 9;
		max-height: 350px;
		background: white;
		overflow: auto;
		.ss-option {
			font-weight: 400!important;
			padding-left: 14px;
			&.ticked {
				&:before {
					content: '';
				}
			}
			&.highlighted {
				background-color: #0080ff;
				color: white;
			}
		}
	}

	.ss-selected-option {
		background-image: none;
		background-color: #fff;
		border: 0;
		outline: inherit;
		padding: 0px 12px;
		height: 40px;
		font-weight: 400!important;
		display: flex;
		align-items: center;
		&:after {
			color: #0080ff;
			top: 12px;
		}
	}
}

.search-events-section,
.search-judoka-section {
	input {
		width: 100%;
		height: 40px;
		padding: 0px 8px;
		border: 1px solid #b5b5b5;
		border-radius: 2px;
		font-family: "Roboto Condensed";
		font-size: 15px;
		line-height: 18px;
		font-weight: 400;
		text-transform: none;
		outline: inherit;
	}
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
    border-color: #0080ff;
}

.splide__slide img {
	width : 100%;
	height: auto;
	max-width: 100%;
}

.sidebar-slide {
	max-width: 350px;
	@include down(sm) {
		max-width: 300px;
	}
	.element-header {
        .element-title-wrapper {
          a {
            color: white;
          }
		}
	}
	.splide {
		max-width: 350px;
		@include down(sm) {
			max-width: 300px;
		}
		.splide__slide {
			max-width: 350px;
			@include down(sm) {
				max-width: 300px;
			}
		}
	}
}

.img-overlay-opacity {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 128 255 / 20%);
}

#search {
	.tt-dropdown-menu {
		max-height: 150px;
		overflow-y: auto;
	}
} 

.slideshow {
	max-width: 350px!important;
	height: auto;
	a {
		max-width: 350px!important;
		height: auto;
		img {
			max-width: 350px!important;
			object-fit: contain;
			height: 100%;
		}
	}
}

.judo-stats-row {
	padding: 6px 12px;
	&:nth-child(2n) {
		background-color: #eeeeee;
	}
}

.highlight-data {
	.judo-stats-row {
		background-color: white;
	}
}

@include up(lg) {
	header {
		.header-main-menu {
			&:first-child {
				.menu-item {
					&:first-child {
						padding-left: 5px;
					}
				}
			}
		}
	}
}

.judo-statistics-section {
	.statistics-tab-wrapper {
		@include flexColumn();
		.tabs-header {
		  flex-shrink: 0;
		  @include flexRow(flex-start);
		  width: 100%;
		  height: 35px;
		  .tab-header {
			@include flexRow($inline: true);
			height: 100%;
			padding: 0px 32px 0px 20px;
			background-color: #0080ff;
			color: #a4cffa;
			clip-path: polygon(0% 0%, calc(100% - 12px) 0%, 100% 100%, 0% 100%);
			span {
			  padding-top: 5px;
			  @include font($teko, 18px, 500, uppercase);
			  cursor: pointer;
			  user-select: none;
			}
			&.selected {
			  color: #fff;
			}
		  }
		}
	
		.tabs-content {
		  width: 100%;
		  height: 100%;	
		  .tab-content {
			@include flexColumn();
			-webkit-animation: fadein 0.6s;
			-moz-animation: fadein 0.6s;
			-ms-animation: fadein 0.6s;
			-o-animation: fadein 0.6s;
			animation: fadein 0.6s;
			width: 100%;
			height: 100%;
			&:not(.visible) {
			  display: none;
			}
		  }
		}
	}
}

.judo-object-position-top {
	img {
		object-position: top;
		@include down(sm) {
			object-position: unset;
		}
	}
}

.judo-object-position-center {
	img {
		object-position: center;
		@include down(sm) {
			object-position: unset;
		}
	}
}