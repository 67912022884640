.container {
    &-wider {
      max-width: 95vw;
      @include up(xl) {
        margin: 0 0 0 auto;
        max-width: 97vw;
      }
      @include up(fhd) {
        margin: 0 4vw 0 auto;
        max-width: 84.2vw;
        padding-right: 0;
        .row {
          margin-right: 0;
        }
      }
    }
  }
  