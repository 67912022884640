.home-featured-section {
  width: 100%;

  overflow: hidden;

  .home-featured-content {

    .home-featured-header {
      width: 100%;
      margin: 48px 0 38px;

      @include up(md) {
        padding: 0px 50px;
      }
      @include down(md) {
        padding: 0px;
      }

      @include down(lg) {
        text-align: center;
      }

      span {
        @include font($robotoCondensed, 30px, 700, uppercase);
      }
    }

    .home-featured-body {
      @include up(xl) {
        @include flexRow(space-between);

        height: 432px;
      }
      @include down(xl) {
        @include flexColumn();

        @include up(md) {
          padding: 0px 50px;
        }
        @include down(md) {
          padding: 0px;
        }
      }

      width: 100%;

      position: relative;

      .content-wrapper-xl {
        @include down(xl) {
          display: none;
        }

        @include flexColumn(center, flex-start);

        height: 100%;
        padding-right: 86px;
        padding-left: 50px;

        background: rgb(0,67,134);
        background: -moz-linear-gradient(280deg, rgba(0,67,134,1) 0%, rgba(0,128,255,1) 100%);
        background: -webkit-linear-gradient(280deg, rgba(0,67,134,1) 0%, rgba(0,128,255,1) 100%);
        background: linear-gradient(280deg, rgba(0,67,134,1) 0%, rgba(0,128,255,1) 100%);

        z-index: 2;

        clip-path: polygon(0% 0%, calc(100% - 86px) 0%, 100% 100%, 0% 100%);

        .content {
          @include flexColumn(space-between, flex-start);

          width: 367px; /*503px ?*/
          height: 100%;
          padding-top: 66px;
          padding-bottom: 78px;

          .quote {
            @include flexColumn(center, flex-start);

            width: 317px;
  
            color: #fff;

            .title {
              margin-bottom: 20px;
  
              @include fontAlt($robotoCondensed, 30px, 35px, 400, uppercase);
            }
  
            .text {
              @include fontAlt($robotoCondensed, 18px, 21px, 400);
            }
          }

          .actions {
            @include flexRow(space-between);
            @include horizontalMargins(45px / 2);

            width: 100%;
          }
        }
      }

      .quote-portrait {
        @include up(xl) {
          flex-grow: 30;

          height: 100%;
          margin-left: -86px;

          object-fit: cover;
          z-index: 1;
        }
        
        @include down(xl) {
          width: 100%;
        }
      }

      .content-sm {
        @include up(xl) {
          display: none;
        }

        padding: 29px 20px 39px;

        background: #0080ff;
        background: -moz-linear-gradient(180deg, #0080ff 0%, #0059b2 100%);
        background: -webkit-linear-gradient(180deg, #0080ff 0%, #0059b2 100%);
        background: linear-gradient(180deg, #0080ff 0%, #0059b2 100%);

        .quote {
          @include flexColumn(center, flex-start);

          color: #fff;

          span {
            width: 100%;

            margin-bottom: 20px;
          }

          .text {
            @include fontAlt($robotoCondensed, 22px, 27px, 400);
          }

          .name {
            text-align: right;

            @include fontAlt($robotoCondensed, 20px, 24px, 700, uppercase);
          }
        }

        .actions {
          @include flexColumn();
          @include verticalMargins(24px / 2);

          margin-top: 19px;
        }
      }

      .action {
        @include flexRow();

        width: 100%;
        height: 43px;

        background-color: #fff;

        clip-path: polygon(0% 0%, calc(100% - 14px) 0%, 100% 50%, calc(100% - 14px) 100%, 0% 100%);

        a {
          @include flexRow();

          width: 100%;
          height: 100%;

          padding-right: 14px;
        }

        span {
          @include font($robotoCondensed, 21px, 700, uppercase);

          color: #153977;
        }
      }
    }
  }
}