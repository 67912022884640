@mixin font($fontFamily, $fontSize, $fontWeight, $textTransform: none) {
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
  text-transform: $textTransform;
}

@mixin fontAlt($fontFamily, $fontSize, $lineHeight, $fontWeight, $textTransform: none) {
  font-family: $fontFamily;
  font-size: $fontSize;
  line-height: $lineHeight;
  font-weight: $fontWeight;
  text-transform: $textTransform;
}

a {
  cursor: pointer;

  &:focus {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }

  &.details-link {
    display: inline;

    color: inherit;

    transition-property: color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;

    &:hover {
      color: #0080ff;
    }
  }

  &.details-link-bare {
    display: inline;

    color: inherit;
  }
}