@mixin genericVideoListItem() {
  @include flexColumn();

  .img-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 575px;
      max-height: 304px;
      transition: all ease 0.3s;
      @include down(md) {
        max-width: none;
        max-height: none;
      }
    }

    .img-overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)!important;
    }
  }

  .info {
    @include flexColumn();

    width: 100%;

    .created-at {
      width: 100%;
      padding: 9px 0;

      border-bottom: 1px solid #b6dbff;

      @include fontAlt($robotoCondensed, 16px, 26px, 400);

      color: #808080;
    }

    .title {
      width: 100%;

      border-bottom: 1px solid #0080ff;

      @include up(md) {
        min-height: 71px;

        padding: 8px 0;

        @include fontAlt($robotoCondensed, 19px, 27px, 400);
      }

      @include down(md) {
        padding: 13px 0;

        @include fontAlt($robotoCondensed, 17px, 27px, 400);
      }

      color: #191919;
    }
  }
}

@mixin genericVideoListSectionWithHeader() {
  margin-bottom: 48px;

  .video-list-section-header {
    @include flexRow();

    width: 100%;

    margin-bottom: 29px;

    .header-band {
      @include flexRow(flex-start);
    
      width: 100%;
      height: 43px;

      padding: 3px 20px;

      @include fontAlt($teko, 30px, 27px, 400, capitalize);

      background-color: #0080ff;
      color: #fff;

      img {
        width: 42px;
        height: 23px;

        margin-right: 16px;
      }

      .title {
        display: inline-block;

        margin-top: 3px;
      }

      @include down(md) {
        padding: 3px 9px;

        font-size: 25px;

        img {
          width: 33px;
          height: 18px;

          margin-right: 6px;
        }
      }
    }

    .link-button {
      flex-shrink: 0;
      @include largeBlueButton();

      margin-left: 20px;

      &.vis-md {
        padding: 0 17px 0 10px;

        @include down(md) {
          display: none;
        }
      }

      &.vis-sm {
        padding: 0 13px 0 8px;
        margin-left: 9px;

        span {
          font-size: 22px;
          line-height: 31px;
        }

        @include up(md) {
          display: none;
        }
      }
    }
  }

  .video-list-section-body {
    @include responsiveGridCol3();

    width: 100%;

    .video-item {
      @include genericVideoListItem();
    }
  }
}

@mixin genericNewsListHighlightedItem() {
  .list-item-header {
    @include flexRow(flex-start);

    height: 35px;

    .category-wrapper {
      @include flexRow($inline: true);
      height: 100%;
      padding: 0px 32px 0px 20px;

      background-color: #0080FF;
      color: #fff;

      clip-path: polygon(0% 0%, calc(100% - 12px) 0%, 100% 100%, 0% 100%);

      .category {
        padding-top: 5px;

        @include font($teko, 18px, 500, uppercase);
      }
    }
  }

  .list-item-body {
    @include flexColumn();

    position: relative;
    overflow: hidden;
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    img {
      width: 100%;
      max-width: 710px;
      max-height: 100%;
      height: 403px;
      object-fit: cover;
      transition: all ease 0.3s;
    }

    .content-wrapper {
      @include flexColumn(flex-end);

      height: 100%;
      width: 100%;
      padding: 20px;

      position: absolute;

      .title {
        width: 100%;
        background-color: rgba(0, 128, 255, 0.4);
        padding: 6px 12px;
        @include fontAlt($robotoCondensed, 30px, 34px, 700, uppercase);

        @include down(sm) {
          font-size: 26px;
          line-height: 29px;
        }

        color: #fff;
      }

      .bottom-line {
        @include flexRow(flex-start);
        @include horizontalMargins(17px / 2);

        width: 100%;

        padding: 12px 0;

        border-top: 1px solid #fff;

        .date {
          @include font($robotoCondensed, 15px, 400);

          color: #fff;
        }

        .read-more {
          @include smallArrowButton();
        }
      }
    }
  }
}

@mixin genericNewsListOtherItem() {
  @include flexColumn(flex-start);
  
  width: 100%;
  padding: 16px 0 9px;

  border-bottom: 1px solid #b6dbff;

  &:first-child {
    border-top: 1px solid #b6dbff;
  }

  .created-at {
    width: 100%;

    margin-bottom: 8px;

    @include fontAlt($robotoCondensed, 15px, 24px, 400);

    color: #191919;
  }

  .title {
    width: 100%;

    a {
      width: 100%;

      @include up(md) {
        @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);

        color: #333333;
      }
      
      @include down(md) {
        @include fontAlt($robotoCondensed, 20px, 24px, 400, uppercase);

        color: #6a6a6a;
      }
    }
  }
}