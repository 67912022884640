
// rem calculator
// usage example: font-size: rem(16); 

$browser-context: 16; // Default

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

// line height calculator
// usage example: line-height: lh(20px, 16px);

@function lh($line-height, $font-size) {
  @return $line-height / $font-size;
}  

// media queries

$breakpoints: (
  xs: 320px,
  sm: 576px,
  smd: 692px,
  md: 768px,
  lg: 992px,
  sxl: 1012px,
  xl: 1200px,
  hd: 1440px,
  shd: 1660px,
  fhd: 1920px
);
// Respond above.
@mixin up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


//-------------------------------------------------
// @include up(sm) {
//   .element {
//     font-weight: bold;
//   }
// }
//-------------------------------------------------

@mixin down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//-------------------------------------------------
// @include down(sm) {
//   .element {
//     font-weight: bold;
//   }
// }
//-------------------------------------------------


// between
@mixin betw($lower, $upper) {

  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    @if (map-has-key($breakpoints, $lower) == false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    @if (map-has-key($breakpoints, $upper) == false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
//-------------------------------------------------
// @include betw(sm, md) {
//   .element {
//     font-weight: bold;
//   }
// }
//-------------------------------------------------

@keyframes fade {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}