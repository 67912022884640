/* Toggling visibility */

.hide-down-sm {
  @include down(sm) {
    display: none !important;
  }
}

.hide-down-md {
  @include down(md) {
    display: none !important;
  }
}

.hide-down-lg {
  @include down(lg) {
    display: none !important;
  }
}

.hide-up-sm {
  @include up(sm) {
    display: none !important;
  }
}

.hide-up-md {
  @include up(md) {
    display: none !important;
  }
}

.hide-up-lg {
  @include up(lg) {
    display: none !important;
  }
}