.profile-tab-content.head-to-head {
  .title {
    margin-top: 48px;

    &.results-comparison {
      @include down(md) {
        display: none;
      }
    }
  }

  .filter-opponent-wrapper {
    @include up(md) {
      @include flexRow(space-between);
      @include horizontalMargins(16px / 2);
    }

    @include down(md) {
      @include flexColumn();
      @include verticalMargins(16px / 2);
    }

    width: 100%;

    padding: 35px 44px;

    background-color: #f5f5f5;

    .filter-opponent-label {
      flex-shrink: 0;
    }

    .filter-opponent {
      @include genericSelectGroup();
    }
  }

  .match-balance {
    @include tableWithBlueHeader();
  }

  .head-to-heads {
    table.hth-lg {
      @include genericHeadToHeadTableLg();
    }

    .hth-sm {
      @include genericHeadToHeadTablesSm();
    }
  }

  .results-comparison-md {
    @include genericResultsComparisonMd();
  }
}