.profile-tab-content.photos {
  .photos-list {
    width: 100%;

    @include responsiveGridCol3();

    .photo-list-item {
      @include flexColumn();

      .img-wrapper {
        width: 100%;

        position: relative;

        .related-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          max-width: 460px;
          max-height: 252px;
          @include down(md) {
            max-width: none;
            max-height: none;
          }
        }
      }
    }
  }
}