@mixin medalCircle() {
  display: inline-block;

  width: 27px;
  height: 27px;

  border-radius: 50%;

  text-align: center;

  background-color: #fff;

  img {
    height: 18px;
    transform: translateY(1.5px);
  }
}

@mixin queryResults() {
  @include down(lg) {
    margin-left: 18px;
    margin-right: 18px;
  }

  .results-title {
    margin-bottom: 15px;

    @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);

    color: #333333;
  }

  .results-table-lg {
    @include up(lg) {
      @include tableWithBlueHeader();

      > tbody {
        > tr.highlighted {
          border-bottom: 1px solid #7bc1f6;

          @include fontAlt($robotoCondensed, 30px, 36px, 700);

          > td {
            padding: 30px 12px 10px;

            color: #191919;

            vertical-align: bottom;

            .highlight-wrapper {
              @include flexColumn(center, flex-start);

              img {
                width: 171px;
                height: 148px;

                object-fit: cover;

                margin-bottom: 11px;
              }
            }
          }
        }

        > tr {
          > th, td {
            &.nr, &.total {
              width: 1px;
              white-space: nowrap;
            }

            &.gold, &.silver, &.bronze, &.total {
              text-align: right;
            }
          }
        }
      }
    }

    @include down(lg) {
      display: none;
    }
  }

  .results-table-wrapper-sm {
    .highlighted {
      @include flexRow();

      width: 100%;

      > img {
        flex-shrink: 0;

        width: 83px;
        height: 92px;

        margin-right: 7px;

        object-fit: cover;
      }

      .highlight-data {
        @include flexColumn();

        width: 100%;

        .data-upper {
          @include flexRow(space-between);

          width: 100%;

          margin-bottom: 7px;

          .nr-and-name {
            margin-bottom: -6px;

            .nr {
              @include font($teko, 24px, 700);
  
              color: #0080ff;
            }
  
            .name {
              @include fontAlt($teko, 24px, 34px, 500);
  
              color: #333333;
            }
          }
  
          img {
            width: 52px;
            height: 35px;
          }
        }
        
        .data-lower {
          @include flexRow(flex-start);
          @include horizontalMargins(25px / 2);

          width: 100%;

          padding: 15px 15px 7px 7px;

          background-color: #eeeeee;

          .medal-wrapper {
            @include flexRow();
            @include horizontalMargins(7px / 2);

            .medal-circle {
              @include medalCircle();
            }

            .count {
              @include font($robotoCondensed, 19px, 700);

              color: #191919;
            }
          }
          
        }
      }
    }

    .results-table-sm {
      width: 100%;

      tr {
        th, td {
          padding-top: 10px;
          padding-bottom: 10px;

          border-bottom: 1px solid #b6dbff;

          &.gold, &.silver, &.bronze {
            width: 42px;
            white-space: nowrap;

            text-align: center;
          }
        }

        &.head {
          th {
            .medal-circle {
              @include medalCircle();
            }
          }
        }
        
        &.body {
          td {
            @include fontAlt($robotoCondensed, 17px, 21px, 400);

            color: #050505;

            &.nr {
              @include font($teko, 22px, 400);

              color: #0080ff;
            }
          }
        }
      }
    }

    @include up(lg) {
      display: none;
    }
  }
}

@mixin queryForm() {
  @include flexRow();

  width: 100%;

  margin-bottom: 48px;

  background-color: #f5f5f5;

  .cta-tab {
    flex-shrink: 0;

    @include flexColumn();

    width: 53px;
    height: 100%;

    background: #0064c7;
    background: -moz-linear-gradient(180deg, #0064c7 0%, #004386 100%);
    background: -webkit-linear-gradient(180deg, #0064c7 0%, #004386 100%);
    background: linear-gradient(180deg, #0064c7 0%, #004386 100%);

    .cta-text {
      margin-right: -5px;

      writing-mode: vertical-lr;
      transform-origin: center;
      transform: rotate(180deg);

      @include font($teko, 28px, 500, uppercase);
      color: #c6e3ff;
    }
  }

  .form-body {
    @include flexColumn(space-between);

    width: 100%;
    height: 100%;

    padding: 0 25px;

    .inputs-wrapper {
      @include flexColumn(center, stretch);

      width: 100%;
      height: 100%;

      .inputs {
        @include responsiveGridCol3(29px, 27px, md, lg);
        @include down(lg) {
          row-gap: 21px;
          column-gap: 16px;
        }
        align-items: center;
  
        width: 100%;

        .select-group {
          @include genericSelectGroup();
        }
  
        .input-group-set {
          @include flexRow();
          @include horizontalMargins(16px / 2);
  
          width: 100%;
        }
      }
    }

    .actions {
      flex-shrink: 0;
      
      @include flexRow();

      width: 100%;

      margin-bottom: 35px;

      @include down(lg) {
        justify-content: flex-start;
        margin-bottom: 30px;
      }

      .find-button {
        .hajime-button {
          font-size: 24px;
          @include largeBlueButton();
        }
      }
    }
  }
}

.judo-stats-generator-section {
  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .judo-stats-generator-content {
    margin-bottom: 275px;

    .query-form-wrapper {
      @include queryForm();

      @include up(lg) {
        height: 296px;
      }

      @include betw(md, lg) {
        height: 342px;
      }

      @include down(md) {
        height: 478px;
      }
    }

    .query-results {
      @include queryResults();
    }
  }
}