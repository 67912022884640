.countries-section {
  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .countries-content {
    @include flexColumn();

    @include down(md) {
      padding: 0 17px;
    }

    margin-bottom: 60px;

    .countries-search {
      @include flexColumn(center, flex-start);
      @include verticalMargins(21px / 2);

      margin-bottom: 35px;

      @include down(md) {
        align-items: center;
      }

      width: 100%;

      .input-group {
        @include genericInputGroup();
        max-width: 302px;

        &.search {
          input {
            &::placeholder {
              color: #bfbfbf;
            }
          }

          .input-overlay {
            .controls-wrapper {
              img {
                transform: translate(3px, 1px);
              }
            }
          }
        }
      }

      .select-group {
        @include genericSelectGroup();
        max-width: 302px;

        &.select {
          @include up(md) {
            display: none;
          }
        }
      }
    }

    .countries-by-initial-letter {
      @include flexColumn();
      @include verticalMargins(48px / 2);

      width: 100%;

      .country-by-initial-letter {
        width: 100%;

        .list-header {
          @include flexRow(flex-start);

          width: 100%;
          height: 42px;

          padding: 0 10px;
          margin-bottom: 21px;

          background-color: #0080ff;

          span {
            display: inline-block;

            @include fontAlt($teko, 30px, 27px, 400, uppercase);

            color: #fff;
          }
        }

        .list-body {
          display: grid;
          column-gap: 39px;
          row-gap: 16px;

          width: 100%;

          @include up(lg) {  
            grid-template-columns: 1fr 1fr 1fr;
          }

          @include down(lg) {
            grid-template-columns: 1fr 1fr;
          }

          @include down(sm) {
            grid-template-columns: 1fr;
          }

          .list-item {
            @include flexRow(flex-start);
            @include horizontalMargins(20px / 2);

            padding: 18px 14px 13px;

            border: 1px solid #e8e8e8;
            border-radius: 2px;

            background: #f0f0f0;
            background: -moz-linear-gradient(180deg, #fff 0%, #f0f0f0 100%);
            background: -webkit-linear-gradient(180deg, #fff 0%, #f0f0f0 100%);
            background: linear-gradient(180deg, #fff 0%, #f0f0f0 100%);

            img {
              width: 61px;
              height: 41px;
            }

            .list-item-name {
              @include fontAlt($robotoCondensed, 16px, 34px, 700, uppercase);

              color: #000000;
            }
          }
        }
      }
    }
  }
}