.video-details-section {
  @mixin titleText {
    @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);

    color: #333333;
  }

  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .video-details-content {
    margin-bottom: 60px;

    .title {
      width: 100%;

      @include titleText();
    }

    > .info {
      @include up(lg) {
        @include flexRow(space-between);

        padding: 12px 0 10px;
        margin-top: 15px;
        margin-bottom: 48px;

        border-top: 1px solid #b6dbff;
        border-bottom: 1px solid #b6dbff;
      }

      @include down(lg) {
        @include flexColumn(center, flex-start);
        @include verticalMargins(16px / 2);

        padding: 14px 0 16px;
        margin-top: 5px;
        margin-bottom: 41px;

        border-top: 2px solid #b6dbff;
        border-bottom: 2px solid #b6dbff;
      }

      .created-at, .video-credit {
        @include horizontalMargins(11px / 2);

        span {
          @include font($robotoCondensed, 15px, 400);

          color: #191919;
        }
      }
    }

    .details-img-wrapper {
      width: 100%;

      margin: 19px 0;

      @include up(md) {
        @include aspectRatioContainer(996px, 548px);
      }
      
      @include down(md) {
        @include aspectRatioContainer(306px, 177px);
      }

      .details-img {
        @include aspectRatioContent();
        object-fit: cover;
        max-width: 1458px;
        max-height: 800px;
        @include up(fhd) {
          max-height: inherit;
          max-width: inherit;
        }
      }

      .details-img-overlay {
        height: 21.9%;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    > :not(.details-img-wrapper) {
      @include down(md) {
        margin-left: 21px;
        margin-right: 21px;
      }
    }

    .social-actions {
      @include flexRow(flex-start);
      @include horizontalMargins(17px / 2);

      padding: 6px 0 32px;

      border-bottom: 2px solid #b6dbff;

      .share {
        @include flexRow();

        width: 69px;
        height: 30px;

        padding: 0 7px;

        border-radius: 2px;

        background-color: #0080ff;
        color: #fff;

        img {
          flex-grow: 0;
          flex-shrink: 0;
        }

        span {
          display: inline-block;

          width: 100%;

          text-align: center;

          @include font($robotoCondensed, 15px, 400);
        }
      }
    }

    .related {
      @include flexColumn();

      @include up(md) {
        width: 100%;

        margin-top: 48px;
      }

      @include down(md) {
        width: calc(100% - 42px);

        margin-top: 64px;
      }

      .related-header {
        @include flexRow(flex-start);

        width: 100%;

        position: relative;

        @include up(md) {
          padding-bottom: 21px;
        }

        @include down(md) {
          padding-bottom: 24px;
        }

        .title {
          @include titleText();
        }
      }

      .related-body {
        width: 100%;
      }

      &.tags {
        padding-bottom: 15px;

        border-bottom: 2px solid #b6dbff;

        .related-body {
          @include responsiveGridCol5();

          .related-tag {
            @include flexRow();

            height: 40px;

            background-color: #0080ff;

            a {
              @include flexRow();

              width: 100%;
              height: 100%;

              color: #fff;
            }

            span {
              margin-top: 6px;

              @include font($teko, 18px, 500, uppercase);
            }
          }
        }
      }

      &.head-to-heads {
        .related-body {
          table.hth-lg {
            @include genericHeadToHeadTableLg();
          }

          .hth-sm {
            @include genericHeadToHeadTablesSm();
          }
        }
      }

      &.result-comparison {
        .results-comparison-md {
          @include genericResultsComparisonMd();
        }

        @include down(md) {
          display: none;
        }
      }

      &.judo-event-photos {
        .related-body {
          @include responsiveGridCol3();

          .related-item {
            @include flexColumn();
  
            .img-wrapper {
              width: 100%;
              position: relative;
  
              .related-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                max-width: 460px;
                max-height: 252px;
                @include down(md) {
                  max-width: none;
                  max-height: none;
                }
              }
            }
          }
        }
      }
    }
  }
}