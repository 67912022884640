.event-details-tab-content.age-statistics {
  .age-statistics-header {
    @include up(md) {
      @include flexRow();
      @include horizontalMargins(8px / 2);
    }

    @include down(md) {
      @include flexColumn();
      @include verticalMargins(8py / 2);
    }

    margin-bottom: 36px;

    span {
      display: inline-block;

      @include fontAlt($robotoCondensed, 22px, 31px, 500);
    }
  }

  .age-statistics-body {
    @include responsiveGridCol2($columnGap: 75px);

    .statistics {
      @include flexColumn();
      @include verticalMargins(30px / 2);

      width: 100%;

      > table {
        width: 100%;

        tr {
          @include up(md) {
            @include horizontalPaddings(10px, true, true);
          }

          @include down(md) {
            @include horizontalPaddings(5px);
          }

          &.head {
            height: 52px;

            td {
              padding-top: 8px;
            }
          }
          
          &.body {
            height: 39px;
          }

          td {
            border-bottom: 1px solid #b6dbff;
  
            @include fontAlt($robotoCondensed, 19px, 27px, 400);
  
            color: #191919;

            &.age {
              width: 1px;
              white-space: nowrap;

              text-align: right;
            }
          }
        }
      }
    }
  }
}