.event-details-tab-content {
    &.head-to-head {
        .weight-category-controls {
            @include flexRow();
            margin-bottom: 20px;
        .weight-category {
            @include genericSelectGroup();
            margin: auto;
            max-width: 275px;
        }
    }
    .table {
        thead {
            th {
                border-top: 0;
                border-bottom-color: #0080ff;
                padding-left: 0;
                color: #0080ff;
                border-bottom-width: 1px;
            }
        }
        tbody {
            td {
                &:last-child {
                    border-bottom-color: #0080ff;
                    border-bottom-width: 1px;
                }
                &.winner {
                    background-color: #0080ff;
                    color: white;
                    a {
                        color: white;
                    }
                }
                &.country {
                    text-transform: uppercase;
                }
                &.video {
                    text-align: center;
                    .play {
                        @include flexRow($inline: true);
                        @include horizontalMargins(3px / 2);
                        width: 20px;
                        height: 20px;
                        border-radius: 2px;
                        background-color: #0080ff;
                        color: #fff;
                        &:before {
                            content: '';
                            width: 20px;
                            height: 20px;
                            background-image: url('../img/hollow-triangle-right.svg');
                            background-repeat: no-repeat;
                            background-position: center;
                            margin-left: 3px;
                        }
                    }
                }
                a {
                    color: #0080ff;
                }
                img {
                    max-width: 30px;
                }
            }
        }
    }

    @include down(sm)  {
        table {
            display: block;
            margin-bottom: 20px;
            &:nth-of-type(1):before { 
                content: "Final";
            }
            thead {
                display: block;
                tr {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                }
            }
            tbody {
                display: block;
                tr {
                    display: block;
                    border: 1px solid #ccc; 
                    margin-bottom: 5px;
                    td {
                        display: block;
                        border: none;
                        border-bottom: 1px solid #eee; 
                        position: relative;
                        padding-left: 50%;
                        &:before {
                            position: absolute;
                            top: 6px;
                            left: 6px;
                            width: 45%; 
                            padding-right: 10px; 
                            white-space: nowrap;
                        }
                        &.video {
                            text-align: left!important;
                        }
                        &.winner {
                            border-bottom-color: #0080ff;
                        }
                        &:last-child {
                            border-bottom-color: #eee!important;
                        }
                    }
                }
            }
        }
	
        td:nth-of-type(1):before { content: "Name"; }
        td:nth-of-type(2):before { content: "Country"; }
        td:nth-of-type(3):before { content: "Name"; }
        td:nth-of-type(4):before { content: "Country"; }
        td:nth-of-type(5):before { content: "Video"; }
        }
    }
}