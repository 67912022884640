.judoka-profile-section {
  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .judoka-profile-content {
    margin-bottom: 60px;

    .profile-info {
      @include up(md) {
        @include flexRow();
        @include horizontalMargins(40px / 2);

        height: 292px;
      }

      @include down(md) {
        @include flexColumn();
      }

      width: 100%;

      margin-bottom: 54px;

      .portrait-wrapper {
        height: 100%;
        position: relative;
        #portraitName {
          div {
            font-size: 28px;
            background-image: url('../img/portrait-name-background.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            color: white;
            height: 3.8em!important;
            @include down(sm) {
              margin-bottom: 10px;
              font-size: 32px;
            }
            span {
              bottom: 50px!important;
              transform-origin: center 10.75em!important;
            }
          }
        }
        .portrait-country-code {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 75px;
          font-size: 40px;
          text-transform: uppercase;
          font-weight: bold;
          color: #2a2d93;
          @include down(sm) {
            height: 170px;
            align-items: flex-start;
          }
        }
        .judoinside-logo {
          position: absolute;
          width: 100%;
          text-align: center;
          bottom: 0;
          background-color: #0080ff;
          color: white;
          min-height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          span {
            font-style: italic;
            font-weight: bold;
          }
        }
        @include up(md) {
          flex-shrink: 0;

          width: 305px;

          img {
            width: 100%;
            height: 100%;
            max-width: 305px;
            max-height: 292px;
            object-fit: cover;
          }
        }

        @include down(md) {
          width: 100%;

          @include aspectRatioContainer(305px, 292px);
          &.portrait-image-empty {
            @include down(sm) {
              padding-top: inherit;
              height: auto;
            }
          }
          img {
            @include aspectRatioContent();

            object-fit: cover;
          }
        }
      }

      .info {
        @include flexColumn(space-between, flex-start);

        @include up(md) {
          padding: 41px 33px;
        }

        @include down(md) {
          justify-content: center;

          padding: 32px 12px;
        }

        height: 100%;

        background-color: #f5f5f5;

        .name {
          margin-bottom: 4px;

          @include fontAlt($teko, 30px, 43px, 500);
          margin-bottom: 0;
          color: #333333;
        }

        .origin {
          @include up(md) {
            margin-bottom: 8px;
          }

          @include down(md) {
            margin-bottom: 36px;
          }

          @include fontAlt($robotoCondensed, 19px, 27px, 400);

          color: #191919;
        }

        .bio {
          @include up(md) {
            padding-right: 16px;
            margin-right: 16px;
            overflow-y: auto;
          }

          @include fontAlt($robotoCondensed, 15px, 24px, 400);
        }
      }
    }

    .profile-tabs {
      @include horizontalTabList();
      @include down(xl) {
        flex-flow: column;
      }
    }

    .profile-tab-content {
      width: 100%;

      margin-top: 60px;

      @include down(md) {
        padding: 0 17px;
      }

      > .title {
        width: 100%;

        margin-bottom: 18px;
    
        @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);
    
        color: #333333;
      }
    }
  }
}