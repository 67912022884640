.search-section {
    width: 100%;
    overflow: hidden;
    .site-path-indicator {
      @include up(md) {
        margin: 43px 0 27px;
      }
      @include down(md) {
        margin: 43px 21px 27px;
      }
    }
  
  .search-wrapper {
    @include down(md) {
      padding: 0 17px;
    }
    .search-header {
      .title {
        @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);
        color: #333333;
      }
    }
    .search-content {
      .search-element-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        .search-element {
          padding: 10px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          &:after {
            content: "";
            background-image: url('../img/arrow_blue.svg');
            width: 7px;
            height: 10px;
            background-repeat: no-repeat;
            z-index: 1;
            margin-right: 10px;
            margin-top: -1px;
            transition: all 0.4s ease;
          }
          &:hover {
            background: #0080ff;
            &:after {
              margin-right: 15px;
              background-image: url('../img/arrow_white.svg');
            }
            .search-element-link {
              span {
                color: #ffffff;
                padding-left: 10px;
                transition: all 0.4s ease;
                @include down(md) {
                  padding-left: 0;
                }
              }
            }
          }
          .search-element-link {
            @include down(md) {
              max-width: 350px;
            }
            span {
              @include fontAlt($robotoCondensed, 20px, 29px, 400, inherit);
              color: #0080ff;
            }
          }
        }
      }
    }
  }
}

span.twitter-typeahead {
  display: flex!important;
  justify-content: flex-end;
}

#headerSearch {
  .twitter-typeahead {
    input {
      &.tt-input {
        right: 0px!important;
        left: inherit!important;
        background-color: white!important;
      }
      &.tt-hint {
        display: none;
      }
    }
  }

  .tt-open {
    background-color: white;
    z-index: 99;
    margin-left: 10px;
    width: 92%;
    text-align: left;
    padding-left: 5px;
    transition: all 0.4s ease;
    padding-bottom: 5px;
    .tt-selectable {
      color: #000000;
      &:hover {
        background-color: #0080ff;
        color: white;
        padding-left: 10px;
        transition: all 0.4s ease;
      }
      &.tt-cursor {
        background-color: #0080ff;
        color: white!important;
        padding-left: 10px;
        transition: all 0.4s ease;
      }
    }
  }
}