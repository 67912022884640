.judoka-legends-section {
  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .judoka-legends-content {
    @include flexColumn();

    @include down(md) {
      padding: 0 17px;
    }

    > .title {
      width: 100%;
      margin-bottom: 27px;

      @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);

      color: #333333;
    }

    .judoka-legend-item {
      @include genericVideoListSectionWithHeader();
    }
  }
}