.videos-list-section {

  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .videos-list-content {
    @include flexColumn();

    @include down(md) {
      padding: 0 17px;
    }

    > .title {
      width: 100%;
      margin-bottom: 15px;

      @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);

      color: #333333;
    }

    .videos-group {
      @include flexColumn();

      width: 100%;
      margin-bottom: 48px;

      .videos-group-header {
        @include flexRow(flex-start);
        
        width: 100%;
        padding: 10px 20px 3px;
        margin-bottom: 21px;

        @include fontAlt($teko, 28px, 40px, 500);

        background-color: #0080ff;
        color: #fff;

        span {
          display: inline-block;
        }
      }

      .videos-group-body {
        @include responsiveGridCol3();

        width: 100%;

        .video-list-item {
          @include genericVideoListItem();
        }
      }
    }
  }
}