.news-list-section {
  @mixin advertisement {
    @include flexColumn();
    
    width: 100%;
    height: 268px;

    background-color: #989898b3;
    color: #191919;

    span {
      @include fontAlt($robotoCondensed, 15px, 24px, 400);
    }
  }

  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .news-list-content {
    @include flexColumn();

    > .title {
      width: 100%;

      @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);

      color: #333333;

      @include down(md) {
        padding: 0 21px;
      }
    }
  
    .news-list-highlighted {
      display: grid;
      
      width: 100%;
      margin: 42px 0 48px;

      @include up(md) {
        gap: 40px;
      }

      @include down(md) {
        gap: 32px;
      }

      @include up(lg) {
        grid-template-columns: 1fr 1fr;
      }

      @include down(lg) {
        grid-template-columns: 1fr;
      }
  
      .news-list-item {
        @include genericNewsListHighlightedItem();
      }
  
      .advertisement {
        @include up(lg) {
          grid-column-end: span 2;
        }
  
        @include advertisement();

        @include down(md) {
          margin: 8px 36px;
          width: calc(100% - 72px);
        }
      }
    }
  
    .news-list-others {
      width: 100%;

      @include down(md) {
        padding: 0px 18px;
      }
  
      .news-list-others-item {
        @include genericNewsListOtherItem();
        @include flexRow();
        width: 100%;
        .thumbnail {
          flex-grow: 0;
          height: 100%;
          width: 100%;
          max-width: 93px;
          max-height: 63px;
          object-fit: cover;
          @include down(sm) {
            display: none;
          }
        }
        .content {
          @include flexColumn(space-between, flex-start);
          width: 100%;
          padding-left: 20px;
        }
      }
    }
  
    .load-more {
      @include largeBlueButton();

      margin: 60px 0 48px;
    }
  
    > .advertisement {
      @include advertisement();

      @include down(md) {
        display: none;
      }

      margin-bottom: 42px;
    }
  }
}