.judo-statistics-section {
  overflow: hidden;

  width: 100%;

  .judo-statistics-content {
    @include up(md) {
      margin: 0 50px 48px;
    }
    @include down(md) {
      margin: 0 0 48px;
    }

    .judo-statistics-head {
      width: 100%;
      margin: 55px 0 53px;

      @include down(lg) {
        text-align: center;
      }

      span {
        @include font($robotoCondensed, 30px, 700, uppercase);
      }
    }

    .judo-statistics-body {
      display: grid;
      gap: 40px;
      
      @include up(hd) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @include betw(lg, hd) {
        grid-template-columns: 1fr 1fr;
      }
      @include down(lg) {
        grid-template-columns: 1fr;
      }

      @include down(sm) {
        display: block;
      }

      .statistic-wrapper {
        @include down(sm) {
          padding: 6px 0px;
        }
        .statistic-head {
          @include flexRow(flex-start);

          height: 35px;

          .statistic-name-wrapper {
            @include flexRow($inline: true);
            height: 100%;
            padding: 0px 32px 0px 20px;
  
            background-color: #0080FF;
            color: #fff;

            clip-path: polygon(0% 0%, calc(100% - 12px) 0%, 100% 100%, 0% 100%);

            .statistic-name {
              padding-top: 5px;

              @include font($teko, 18px, 500, uppercase);
            }
          }
        }
        .statistic-body {
          @include flexColumn(space-between);
          
          width: 100%;
          height: calc(100% - 35px);
          padding-top: 36px;
          padding-bottom: 30px;

          border: 1px solid #c7c7c7;

          .view-more-wrapper {
            margin-top: 35px;
            @include smallArrowButton(#fff, #0080ff);
          }

          &.most-popular-judoka,
          &.today {
            padding-left: 36px;
            padding-right: 36px;
            height: 100%;
            table {
              width: 100%;
  
              tr.head {
                width: 100%;
  
                th {
                  @include font($teko, 16px, 500, uppercase);
  
                  color: #a0a0a0;
  
                  &.country {
                    width: .1%;
                    white-space: nowrap;
                  }
                }
              }
  
              tr.head + tr.body {
                td {
                  padding: 0 0 9px;
                }
              }
  
              tr.body {
                width: 100%;
  
                border-bottom: 1px solid #dbdbdb;
  
                td {
                  padding: 11px 0 9px;
  
                  &.name {
                    a {
                      @include font($robotoCondensed, 20px, 700, uppercase);
  
                      color: #000;
                    }
                  }
  
                  &.country {
                    a {
                      width: .1%;
                      white-space: nowrap;
                      vertical-align: bottom;
    
                      @include font($robotoCondensed, 15px, 400, uppercase);
    
                      color: #1f1f1f;
                    }
                  }
                }
              }
            }
          }

          &.featured-events {
            .events {
              display: grid;
              gap: 24px;
              grid-template-columns: 1fr 1fr;

              width: 100%;
              padding-left: 24px;
              padding-right: 24px;

              .event-wrapper {
                @include flexColumn();
                align-self: end;

                .event-title {
                  margin-bottom: 16px;
                  min-height: 45px;
                  vertical-align: middle;
                  text-align: center;
                  a {
                    @include font($robotoCondensed, 20px, 700, uppercase);
                    text-align: center;
                    color: #000;
                  }
                }

                .event-circle {
                  @include flexColumn();

                  width: 144px;
                  height: 144px;
                  border-radius: 50%;

                  background-color: #0080ff;
                  color: #fff;

                  .event-days-left-nr {
                    display: inline-block;

                    @include fontAlt($teko, 40px, 48px, 700, uppercase);
                  }

                  .event-days-left {
                    display: inline-block;
                    
                    @include font($teko, 18px, 700, uppercase);
                  }
                }
              }
            }
          }

          &.judo-stats-generator {
            padding-left: 36px;
            padding-right: 36px;

            table {
              width: 100%;
  
              tr.head {
                width: 100%;
  
                th {
                  @include font($teko, 16px, 500, uppercase);
  
                  color: #a0a0a0;
  
                  &.gold, &.silver, &.bronze, &.total {
                    width: .1%;
                    white-space: nowrap;
                    text-align: right;
                  }

                  &.gold, &.silver, &.bronze {
                    padding-right: 8px;
                  }
                }
              }
  
              tr.head + tr.body {
                td {
                  padding-top: 0;
                }
              }
  
              tr.body {
                width: 100%;
  
                border-bottom: 1px solid #dbdbdb;
  
                td {
                  padding-top: 11px;
                  padding-bottom: 9px;

                  @include font($robotoCondensed, 20px, 700, uppercase);

                  color: #000;
                  
                  &.gold, &.silver, &.bronze, &.total {
                    width: .1%;
                    white-space: nowrap;
                    text-align: right;
                  }

                  &.gold, &.silver, &.bronze {
                    padding-right: 8px;
                  }
                  &.name {
                    a {
                      @include font($robotoCondensed, 20px, 700, uppercase);
                      color: #000;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}