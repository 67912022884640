.home-top-section {
  overflow: hidden;

  width: 100%;

  .home-top-content {
    width: 100%;

    position: relative;

    .content-background {
      width: 100%;
      max-height: 550px;
      object-fit: cover;
      height: 100%;
      min-height: 550px;
      @include betw(lg, hd) {
        max-height: 740px;
      }

      @include up(hd) {
        max-height: 840px;
      }

      @include down(sm) {
        min-height: 450px;
      }
    }

    .home-page-tabs-wrapper {
      @include flexColumn();

      position: absolute;

      @include up(sm) {
        width: 476px;
        height: 369px;

        left: 50px;
        bottom: 82px;
      }

      @include down(sm) {
        width: calc(100vw - 36px);
        max-width: 476px;
        height: 330px;

        left: 18px;
        bottom: 41px;
      }

      .tabs-header {
        flex-shrink: 0;
        @include flexRow(flex-start);

        width: 100%;
        height: 35px;

        .tab-header {
          @include flexRow($inline: true);
          height: 100%;
          padding: 0px 32px 0px 20px;

          background-color: #0080ff;
          color: #a4cffa;

          clip-path: polygon(0% 0%, calc(100% - 12px) 0%, 100% 100%, 0% 100%);

          span {
            padding-top: 5px;

            @include font($teko, 18px, 500, uppercase);

            cursor: pointer;
            user-select: none;
          }

          &.selected {
            color: #fff;
          }
        }
      }

      .tabs-content {
        width: 100%;
        height: 100%;

        background: rgba(#bebcbc, 0.55);

        .tab-content {
          @include flexColumn();
          -webkit-animation: fadein 0.6s;
          -moz-animation: fadein 0.6s;
          -ms-animation: fadein 0.6s;
          -o-animation: fadein 0.6s;
          animation: fadein 0.6s;
          width: 100%;
          height: 100%;

          &.featured-event {
            @include flexColumn(space-between);
      
            @include up(sm) {
              padding: 30px 20px;
            }

            @include down(sm) {
              padding: 27px 11px 9px;
            }
      
            color: #fff;
      
            .title {
              width: 100%;
              @include up(sm) {
                @include fontAlt($robotoCondensed, 50px, 50px, 700, uppercase);
              }

              @include down(sm) {
                @include fontAlt($robotoCondensed, 40px, 50px, 700, uppercase);
              }
            }
      
            .info {
              width: 100%;
      
              @include fontAlt($robotoCondensed, 32px, 38px, 400);
            }
      
            .footer {
              @include flexRow(space-between);
      
              width: 100%;

              @include up(sm) {
                padding: 15px 0 9px 0;
              }

              @include down(sm) {
                padding-top: 9px;
              }

              border-top: 1px solid #fff;
      
              .read-more {
                @include smallArrowButton();
              }
      
              .logos {
                @include flexRow(flex-end);

                @include up(sm) {
                  @include horizontalMargins(30px / 2);
                }

                @include down(sm) {
                  @include horizontalMargins(12px / 2);
                }
              }
            }
          }

          &.latest-news {
            @include up(sm) {
              padding: 22px 27px 22px 25px;
              @include verticalMargins(10px / 2);
            }
      
            @include down(sm) {
              padding: 16px 8px;
              @include verticalMargins(7px / 2);
            }
      
            .latest-news-item {
              @include flexRow();
              width: 100%;
              @include up(sm) {
                height: 90px;
                padding: 0px 10px;
                @include horizontalMargins(17px / 2);
              }
      
              @include down(sm) {
                height: 83px;
                padding: 0px 5px;
                @include horizontalMargins(5px / 2);
              }
      
              color: #191919;
      
              box-shadow: 0px 3px 6px #00000029;
              
              background: #e3e3e3;
              background: -moz-linear-gradient(0deg, #e3e3e3 0%, #fff 100%);
              background: -webkit-linear-gradient(0deg, #e3e3e3 0%, #fff 100%);
              background: linear-gradient(0deg, #e3e3e3 0%, #fff 100%);
      
              .thumbnail {
                flex-grow: 0;
                height: 100%;
                width: 100%;
                max-width: 93px;
                max-height: 63px;
                object-fit: cover;
              }
      
              .content {
                @include flexColumn(space-between, flex-start);
                max-width: 265px;
                width: 100%;
                .time-created {
                  @include font($robotoCondensed, 12px, 400);
                }
      
                .title {
                  min-height: 50px;
                  @include up(sm) {
                    @include fontAlt($robotoCondensed, 19px, 25px, 400);
                  }
                  
                  @include down(sm) {
                    @include fontAlt($robotoCondensed, 17px, 20px, 400);
                  }
                }
              }
      
              .right-arrow {
                flex-grow: 0;
      
                width: 10.5px;
                height: 21px;
      
                background: #0080ff;
      
                clip-path: polygon(0% 0%, 2px 0%, 100% 50%, 2px 100%, 0px 100%);
              }
            }
          }

          &:not(.visible) {
            display: none;
          }
        }
      }
    }
  }
}