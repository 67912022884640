.generic-page-header-section {
  width: 100%;

  overflow: hidden;

  &:not(.simple) {
    .generic-page-header-content {
      position: relative;

      .header-background {
        width: 100%;
        height: 426px;

        object-fit: cover;
      }

      .top-bar {
        width: 100%;
        height: 75px;

        position: absolute;
        top: 0px;
        left: 0px;

        background-color: #0080ff;

        @include down(xl) {
          display: none;
        }
      }

      .bottom-bar {
        @include flexRow();

        width: 100%;
        height: 75px;

        position: absolute;
        bottom: 0px;
        left: 0px;

        .title-wrapper {
          @include flexRow();

          height: 100%;

          padding: 0px 72px;

          background-color: rgba(#fff, 0.3);
          color: #fff;

          clip-path: polygon(22px 0%, calc(100% - 22px) 0%, 100% 100%, 0% 100%);

          .title {
            display: inline-block;
            cursor: default;
            @include fontAlt($robotoCondensed, 30px, 35px, 700, uppercase);
            margin-bottom: 0;
            @include down(md) {
              font-size: 25px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  &.simple {
    .generic-page-header-content {
      @include flexColumn();

      .top-bar {
        width: 100%;
        height: 75px;
  
        background-color: #0080ff;

        @include down(xl) {
          display: none;
        }
      }

      .title-wrapper {
        @include flexRow();

        padding: 0 18px;
        margin-top: 50px; /* 62px ?? */

        color: #333333;

        .title {
          display: inline-block;

          @include fontAlt($robotoCondensed, 30px, 35px, 700, uppercase);
          margin-bottom: 0;
        }
      }
    }
  }
}