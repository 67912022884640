.generic-sidebar-section {
  display: grid;
  gap: 48px;

  @include up(shd) {
    grid-template-columns: 1fr;

    margin-top: 43px;
  }

  @include betw(lg, shd) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include betw(sm, lg) {
    grid-template-columns: 1fr 1fr;
  }

  width: 100%;
  margin-bottom: 48px;
  @include down(sm) {
    width: auto;
  }

  .sidebar-element {
    align-self: start;
    @include flexColumn();

    width: 100%;

    .element-header {
      @include flexRow(flex-start);
      width: 100%;
      height: 40px;

      .element-title-wrapper {
        @include flexRow($inline: true);
        height: 100%;
        padding: 0px 32px 0px 20px;

        background-color: #0080FF;
        color: #fff;

        clip-path: polygon(0% 0%, calc(100% - 12px) 0%, 100% 100%, 0% 100%);

        .title {
          padding-top: 5px;
          line-height: 0;
          @include font($teko, 18px, 500, uppercase);
        }
      }
    }

    .element-body {
      width: 100%;

      border: 1px solid #c7c7c7;

      .sidebar-action-button {
        @include flexRow($inline: true);

        padding: 1px 12px 0 6px;

        background-color: #0080ff;

        clip-path: polygon(0% 0%, calc(100% - 6px) 0%, 100% 50%, calc(100% - 6px) 100%, 0% 100%);

        span {
          @include font($roboto, 12px, 700, uppercase);

          color: #fff;
        }
      }
    }

    &.donate {
      .element-body {
        @include flexColumn();

        .donate-img-wrapper {
          width: 100%;

          @include aspectRatioContainer(305px, 149px);

          .donate-img {
            @include aspectRatioContent();
            max-width: 348px;
            max-height: 170px;
            object-fit: cover;
            @include down(xl) {
              max-width: none;
              max-height: none;
            }
          }
        }

        .donate-content {
          padding: 22px 34px 24px;

          .title {
            @include fontAlt($robotoCondensed, 20px, 24px, 700, uppercase);
          }

          .description {
            margin: 16px 0;

            @include fontAlt($robotoCondensed, 15px, 18px, 400);

            color: #1f1f1f;
          }
        }
      }
    }

    &.featured-events {
      .element-body {
        padding: 34px 21px 21px;

        .events {
          display: grid;
          gap: 24px;
          grid-template-columns: 1fr;

          width: 100%;
          padding-left: 24px;
          padding-right: 24px;

          .event-wrapper {
            @include flexColumn();
            align-self: end;
            a {
              margin-bottom: 16px;
              &.event-title {
                @include font($robotoCondensed, 20px, 700, uppercase);
                text-align: center;
                color: #000;
              }
            }


            .event-circle {
              @include flexColumn();

              width: 144px;
              height: 144px;
              border-radius: 50%;

              background-color: #0080ff;
              color: #fff;

              .event-days-left-nr {
                display: inline-block;

                @include fontAlt($teko, 40px, 48px, 700, uppercase);
              }

              .event-days-left {
                display: inline-block;
                
                @include font($teko, 18px, 700, uppercase);
              }
            }
          }
        }
      }
    }

    &.event-table {
      .element-header {
        .element-title-wrapper {
          a {
            color: white;
          }
        }
      }
      .element-body {
        @include flexColumn();

        padding: 36px 16px 28px;

        table {
          width: 100%;

          tr.head {
            width: 100%;

            th {
              padding: 0 0 8px;

              @include font($teko, 16px, 500, uppercase);

              color: #a0a0a0;

              &.date {
                width: .1%;
                white-space: nowrap;
              }
            }
          }

          tr.head + tr.body {
            td {
              padding: 0 0 11px;
            }
          }

          tr.body {
            width: 100%;

            border-bottom: 1px solid #dbdbdb;

            td {
              padding: 17px 0 11px;

              @include fontAlt($robotoCondensed, 14px, 16px, 700, uppercase);

              color: #000;
              &.opponent {
                a {
                  color: #000;
                }
              }
              &.date {
                min-width: 45px;
              }
            }
          }
        }

        .sidebar-action-button {
          margin-top: 40px;
        }
      }
    }

    &.judo-birthday {
      .element-body {
        @include flexColumn();

        .birthday-portrait-wrapper {
          width: 100%;

          @include aspectRatioContainer(305px, 170px);

          .birthday-portrait {
            @include aspectRatioContent();
            max-width: 348px;
            max-height: 194px;
            object-fit: cover;
            @include down(xl) {
              max-width: none;
              max-height: none;
            }
          }
        }

        .birthday-content {
          @include flexColumn();
          @include verticalMargins(10px / 2);

          width: 100%;
          padding: 17px 17px 27px;

          .name {
            @include fontAlt($robotoCondensed, 20px, 24px, 700);
          }

          .nationality {
            @include flexRow();
            @include horizontalMargins(10px / 2);

            .flag {
              width: 32px;
              height: 21px;
            }

            .country {
              margin-bottom: -3px;

              @include fontAlt($teko, 20px, 29px, 500, uppercase);

              color: #333333;
            }
          }
        }
      }
    }
  }

  .advertisement {
    @include flexColumn();

    width: 100%;
    height: 453px;

    background-color: #989898b3;
    color: #191919;

    span {
      @include fontAlt($robotoCondensed, 15px, 24px, 400);
    }
  }
}