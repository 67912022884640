.contact-us-section {
  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .contact-us-content {
    @include flexColumn();

    @include down(md) {
      padding: 0 17px;
    }

    margin-bottom: 60px;

    .title {
      width: 100%;
  
      @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);
  
      color: #333333;
  
      @include down(lg) {
        margin-left: 28px;
        margin-right: 28px;
      }
    }

    .contact-us-form {
      @include flexColumn();

      width: 100%;

      .description {
        width: 100%;

        margin-top: 44px;
        margin-bottom: 32px;

        @include up(md) {
          text-align: center;
        }

        @include fontAlt($robotoCondensed, 19px, 27px, 400);
      }

      .fieldset {
        width: 100%;

        &.inputs {
          @include flexColumn();
          @include verticalMargins(21px / 2);

          .input-group {
            @include genericInputGroup();
  
            max-width: 765px;
          }

          .select-group {
            @include genericSelectGroup();

            max-width: 765px;
          }

          textarea {
            width: 100%;
            max-width: 765px;
            height: 131px;

            padding: 12px;

            border: 1px solid #b5b5b5;

            @include fontAlt($robotoCondensed, 15px, 18px, 400);
          
            &::placeholder {
              color: #bfbfbf;
            }

            &.message {
              resize: none;
            }
          }
        }

        &.safety-checks {
          @include flexColumn();

          margin-top: 28px;
          margin-bottom: 65px;

          .google-recaptcha-dummy {
            @include flexColumn();

            width: 284px;
            height: 76px;

            margin-bottom: 32px;
  
            background-color: #e8e8e8;
          }

          .checkbox-wrapper {
            @include flexRow();
            @include horizontalMargins(8px / 2);

            input[type="checkbox"] {
              width: 17px;
              height: 17px;

              border: 1px solid #bfcad6;

              transform: translateY(-1px);
            }

            label {
              margin-bottom: 0;

              @include fontAlt($robotoCondensed, 15px, 24px, 400);

              color: #191919;
            }
          }
        }
      }

      button[type="submit"] {
        @include largeBlueButton();
      }
    }
  }
}