.highlighted-tags-section {
  
  width: 100%;

  overflow: hidden;

  @include up(lg) {
    background: #ececec;
    background: -moz-linear-gradient(180deg, #ececec 0%, #fff 100%);
    background: -webkit-linear-gradient(180deg, #ececec 0%, #fff 100%);
    background: linear-gradient(180deg, #ececec 0%, #fff 100%);
  }

  @include down(lg) {
    background: #f0f0f0;
  }
  

  .highlighted-tags-content {
    @include flexColumn();
  
    @include up(xl) {
      padding: 65px 160px;
    }
    @include betw(lg, xl) {
      padding: 50px 88px;
    }
    @include down(lg) {
      padding: 36px 16px 47px;
    }
     
    .tag-group {

      &.profiles {
        margin: 0 0 28px;
      }

      &.events {
        margin: 0 0 28px;
      }

      .tag-group-head {
        margin-bottom: 8px;

        text-align: center;

        @include font($robotoCondensed, 20px, 700, uppercase);

        color: #6a6a6a;
      }

      .tag-group-body {
        @include flexRow($justifyContent: space-between, $wrap: wrap);

        .tag-wrapper {
          flex-grow: 10;
          flex-shrink: 0;
          @include flexRow();

          background-color: #0080ff;

          @include up(lg) {
            height: 40px;
            margin: 8px 5px;
          }

          @include betw(sm, lg) {
            height: 32px;
            margin: 5px;
          }

          @include down(sm) {
            height: 25px;
            margin: 4px;
          }

          a {
            @include flexRow();

            width: 100%;
            height: 100%;

            @include up(lg) {
              padding: 4px 41px;
            }
  
            @include betw(sm, lg) {
              padding: 4px 22px;
            }
  
            @include down(sm) {
              padding: 0 8px;
            }

            color: #fff;

            span {
              margin-top: 2px;
              margin-bottom: -2px;
  
              @include up(lg) {
                @include font($teko, 18px, 500, uppercase);
              }
  
              @include betw(sm, lg) {
                @include font($teko, 16px, 500, uppercase);
              }
  
              @include down(sm) {
                @include font($teko, 14px, 700, uppercase);
              }
            }
          }
        }
      }
    }

    .highlight-cta {
      padding: 0 5px;

      @include font($robotoCondensed, 20px, 400, uppercase);

      color: #6a6a6a;

      span {
        display: inline-block;
      }
    }
  }
}