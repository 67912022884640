.events-judo-results-section {
  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .events-judo-results-content {
    @include flexColumn();

    @include down(md) {
      padding: 0 17px;
    }

    > .title {
      width: 100%;
      margin-bottom: 15px;

      @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);

      color: #333333;
    }

    .judo-results-list {
      @include flexColumn();

      width: 100%;

      margin-bottom: 60px;

      .judo-result {
        @include up(lg) {
          @include flexRow(space-between);

          padding: 17px 25px 17px 0;
          margin-bottom: 6px;
        }
        
        @include down(lg) {
          @include flexColumn();

          padding: 18px 0 16px;
        }

        width: 100%;
        height: 100%;

        border-top: 1px solid #b6dbff;

        &:last-child {
          border-bottom: 1px solid #b6dbff;
        }

        .date-title {
          @include up(lg) {
            flex-basis: 5%;
            flex-grow: 20;
            @include flexColumn(flex-start, flex-start);
          }
          
          @include down(lg) {
            @include flexColumn(center, flex-start);
          }

          width: 100%;

          .date {
            margin-bottom: 5px;

            @include font($robotoCondensed, 15px, 400);

            color: #191919;
          }

          .title {
            margin-bottom: 4px;

            @include fontAlt($robotoCondensed, 23px, 27px, 400, uppercase);
            margin-bottom: 0;
            color: #333333;
          }
        }

        .results-details {
          @include up(lg) {
            align-self: flex-end;
            flex-shrink: 0;
            flex-basis: 5%;
            flex-grow: 20;
            @include flexRow(flex-end, flex-end);
            @include horizontalMargins(40px / 2);
          }

          @include down(lg) {
            @include flexRow(space-between);

            width: 100%;
          }

          .results-count {
            margin-bottom: -7px;

            @include fontAlt($teko, 20px, 29px, 700, uppercase);

            color: #333333;
          }

          .details-link {
            @include flexRow($inline: true);

            background-color: #0080ff;

            clip-path: polygon(0% 0%, calc(100% - 6px) 0%, 100% 50%, calc(100% - 6px) 100%, 0% 100%);

            a {
              @include flexRow();

              width: 100%;
              height: 100%;
              
              padding: 1px 21px 0 15px;
            }

            span {
              @include font($roboto, 12px, 700, uppercase);

              color: #fff;
            }
          }
        }
      }
    }
  }
}