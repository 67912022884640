.profile-tab-content.news {
  @include flexColumn();

  width: 100%;

  overflow: hidden;

  .news-list-highlighted {
    display: grid;
    
    width: 100%;
    margin: 0 0 48px;

    @include up(md) {
      gap: 40px;
    }

    @include down(md) {
      gap: 32px;
    }

    @include up(lg) {
      grid-template-columns: 1fr 1fr;
    }

    @include down(lg) {
      grid-template-columns: 1fr;
    }

    .news-list-item {
      @include genericNewsListHighlightedItem();
    }
  }

  .news-list-others {
    width: 100%;

    @include down(md) {
      padding: 0px 18px;
    }

    .news-list-others-item {
      @include genericNewsListOtherItem();
      @include flexRow();
      width: 100%;
      .thumbnail {
        flex-grow: 0;
        height: 100%;
        width: 100%;
        max-width: 93px;
        max-height: 63px;
        object-fit: cover;
        @include down(sm) {
          display: none;
        }
      }
      .content {
        @include flexColumn(space-between, flex-start);
        width: 100%;
        padding-left: 20px;
      }
    }
  }

  .load-more {
    @include largeBlueButton();

    margin: 60px 0 0;
  }
}