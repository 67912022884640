.news-details-section {
  @mixin titleText {
    @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);

    color: #333333;
  }

  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .news-details-content {
    .title {
      width: 100%;

      @include titleText();
    }

    .details-img-wrapper {
      width: 100%;

      margin: 19px 0;

      @include up(md) {
        @include aspectRatioContainer(995px, 410px);
      }
      
      @include down(md) {
        @include aspectRatioContainer(375px, 175px);
      }

      .details-img {
        @include aspectRatioContent();

        object-fit: cover;
        object-position: top;
        max-width: 1458px;
        max-height: 600px;
      }
    }

    > :not(.details-img-wrapper) {
      @include down(md) {
        margin-left: 21px;
        margin-right: 21px;
      }
    }

    .news-content {
      margin-bottom: 30px;

      @include verticalMargins(32px / 2);

      .content-line {
        color: #191919;

        &.question {
          @include fontAlt($robotoCondensed, 15px, 20px, 700);
        }

        &:not(.question) {
          @include fontAlt($robotoCondensed, 15px, 20px, 400);
        }
      }
    }

    > .info {
      @include up(lg) {
        @include flexRow(space-between);

        padding: 12px 0 10px;
        margin-top: 15px;
        margin-bottom: 48px;

        border-top: 1px solid #b6dbff;
        border-bottom: 1px solid #b6dbff;
      }

      @include down(lg) {
        @include flexColumn(center, flex-start);
        @include verticalMargins(16px / 2);

        padding: 14px 0 16px;
        margin-top: 5px;
        margin-bottom: 41px;

        border-top: 2px solid #b6dbff;
        border-bottom: 2px solid #b6dbff;
      }

      .created-at, .editor, .photos-credit {
        @include horizontalMargins(11px / 2);

        span {
          @include font($robotoCondensed, 15px, 400);

          color: #191919;
        }
      }
    }

    .social-actions {
      @include flexRow(flex-start);
      @include horizontalMargins(17px / 2);

      padding: 18px 0;

      border-bottom: 2px solid #b6dbff;

      .like, .share {
        @include flexRow();

        width: 69px;
        height: 30px;

        padding: 0 7px;

        border-radius: 2px;

        background-color: #0080ff;
        color: #fff;

        img {
          flex-grow: 0;
          flex-shrink: 0;
        }

        span {
          display: inline-block;

          width: 100%;

          text-align: center;

          @include font($robotoCondensed, 15px, 400);
        }
      }
    }

    .related {
      @include flexColumn();

      @include up(md) {
        width: 100%;

        margin-top: 48px;
      }

      @include down(md) {
        width: calc(100% - 42px);

        margin-top: 64px;
      }

      .related-header {
        @include flexRow(flex-start);

        width: 100%;

        position: relative;

        @include up(md) {
          padding-bottom: 9px;
        }

        @include down(md) {
          padding-bottom: 24px;
        }

        .title {
          @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);
        }

        .all-link-md {
          @include largeBlueButton();

          position: absolute;
          right: 0px;
          bottom: 9px;

          @include down(md) {
            display: none;
          }
        }
      }

      .related-body {
        @include responsiveGridCol3();

        width: 100%;

        .related-item {
          @include flexColumn();

          .img-wrapper {
            width: 100%;

            position: relative;

            .related-img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              max-width: 460px;
              max-height: 252px;
              @include down(md) {
                max-width: none;
                max-height: none;
              }
            }

            .related-img-overlay {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .info {
            @include flexColumn();

            width: 100%;

            .created-at {
              width: 100%;
              padding: 9px 0;

              border-bottom: 1px solid #b6dbff;

              @include fontAlt($robotoCondensed, 16px, 26px, 400);

              color: #808080;
            }

            .title {
              width: 100%;
              padding: 11px 0 15px;

              border-bottom: 1px solid #0080ff;

              @include fontAlt($robotoCondensed, 19px, 27px, 400);

              color: #191919;
            }
          }
        }
      }

      .all-link-sm {
        align-self: flex-start;
        @include largeBlueButton();

        margin-top: 24px;

        @include up(md) {
          display: none;
        }
      }

      &.tags {
        padding-bottom: 15px;

        border-bottom: 2px solid #b6dbff;

        .related-body {
          @include responsiveGridCol5();

          .related-tag {
            @include flexRow();

            height: 40px;

            background-color: #0080ff;

            a {
              @include flexRow();

              width: 100%;
              height: 100%;

              color: #fff;
            }

            span {
              margin-top: 6px;

              @include font($teko, 18px, 500, uppercase);
            }
          }
        }
      }

      &.news {
        margin-bottom: 48px;

        .related-body {
          @include flexColumn();
          @include verticalMargins(15px / 2);

          .related-news-item {
            @include flexColumn(center, flex-start);
            @include flexRow();
            width: 100%;
            padding: 15px 0 24px;
            border-bottom: 1px solid #b6dbff;
            .thumbnail {
              flex-grow: 0;
              height: 100%;
              width: 100%;
              max-width: 93px;
              max-height: 63px;
              object-fit: cover;
              @include down(sm) {
                display: none;
              }
            }
            .content {
              @include flexColumn(space-between, flex-start);
              width: 100%;
              padding-left: 20px;
              .created-at {
                width: 100%;
                padding-left: 4px;
                margin-bottom: 4px;
                @include fontAlt($robotoCondensed, 15px, 24px, 400);
                color: #191919;
              }
  
              .title {
                width: 100%;
                a {
                  @include up(md) {
                    @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);
                    color: #333333;
                  }
                  
                  @include down(md) {
                    @include fontAlt($robotoCondensed, 20px, 24px, 400, uppercase);
                    color: #6a6a6a;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}