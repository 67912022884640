.event-details-tab-content.medal-table {
  @include flexColumn();

  .medals {
    width: 100%;
    max-width: 760px;

    tr {
      @include up(md) {
        @include horizontalPaddings(10px, true, true);
      }

      @include down(md) {
        @include horizontalPaddings(5px);
      }

      &.head {
        height: 52px;

        th {
          padding-top: 8px;
        }
      }
      
      &.body {
        height: 39px;
      }

      th, td {
        border-bottom: 1px solid #b6dbff;

        @include fontAlt($robotoCondensed, 19px, 27px, 400);

        color: #191919;

        &.place {
          width: 10%;
          white-space: nowrap;
        }

        &.gold, &.silver, &.bronze {
          width: 7%;
          white-space: nowrap;
        }

        &.total {
          width: 15%;
          white-space: nowrap;
        }

        &.gold, &.silver, &.bronze, &.total {
          text-align: center;
        }
      }
    }
  }
}