.events-judoka-birthday-section {
  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .events-judoka-birthday-content {
    @include flexColumn();

    @include down(md) {
      padding: 0 17px;
    }

    margin-bottom: 60px;
      
    .results-title {
      width: 100%;
  
      @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);
  
      color: #333333;
  
      @include down(lg) {
        margin-left: 28px;
        margin-right: 28px;
      }
    }

    .query-form {
      @include flexRow(space-between);
      @include horizontalMargins(16px / 2);

      width: 100%;
      
      @include up(md) {
        padding: 20px 30px 20px 20px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      
      @include down(md) {
        padding: 20px 10px 20px;
        margin-top: 25px;
        margin-bottom: 36px;
      }

      background-color: #f5f5f5;

      select {
        @include genericSelect();

        &.country {
          max-width: 552px;
        }
      }

      .query-button {
        @include largeBlueButton();
      }
    }

    .results-table {
      @include tableWithBlueHeader();

      .nr {
        width: .1%;
        white-space: nowrap;
      }

      .age {
        text-align: right;
      }
    }
  }
}