.home-galleries-list-section {
  width: 100%;

  overflow: hidden;

  background: rgb(0,67,134);
  background: -moz-linear-gradient(280deg, rgba(0,67,134,1) 25%, rgba(0,128,255,1) 92%);
  background: -webkit-linear-gradient(280deg, rgba(0,67,134,1) 25%, rgba(0,128,255,1) 92%);
  background: linear-gradient(280deg, rgba(0,67,134,1) 25%, rgba(0,128,255,1) 92%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#004386",endColorstr="#0080ff",GradientType=1);

  .home-galleries-list-content {
    @include up(md) {
      margin: 0px 50px;
    }
    @include down(md) {
      margin: 0px;
    }

    .home-galleries-list-header {
      width: 100%;
      margin: 48px 0 38px;

      @include down(lg) {
        text-align: center;
      }

      span {
        @include font($robotoCondensed, 30px, 700, uppercase);

        color: #fff;
      }
    }

    .home-galleries-list-body {
      display: grid;
      gap: 40px;

      width: 100%;

      padding-bottom: 42px;

      @include up(xl) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @include betw(lg, xl) {
        grid-template-columns: 1fr 1fr;
      }
      @include down(lg) {
        grid-template-columns: 1fr;
      }

      .galleries-wrapper {
        transition: all ease 0.3s;
        -webkit-backface-visibility: hidden;
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        image-rendering: auto;

        .galleries-header {
          @include flexRow(space-between);

          height: 35px;

          .category-wrapper {
            @include flexRow($inline: true);
            height: 100%;
            padding: 0px 32px 0px 20px;
    
            background-color: #fff;
            color: #153977;
    
            clip-path: polygon(0% 0%, calc(100% - 12px) 0%, 100% 100%, 0% 100%);
    
            .category {
              padding-top: 5px;
    
              @include font($teko, 18px, 500, uppercase);
            }
          }

          .link-wrapper {
            align-self: flex-start;
            @include smallArrowButton();
            padding: 1px 12px 0 6px;
            @include down(md) {
              margin-right: 19px;
            }
          }
        }

        .img-wrapper {
          @include flexColumn();
          position: relative;
          overflow: hidden;
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
          img {
            width: 100%;
            max-width: 575px;
            max-height: 315px;
            height: 100vh;
            object-fit: cover;
            transition: all ease 0.3s;
          }
          .content-wrapper {
            @include flexColumn(flex-end);
            height: 100%;
            width: 100%;
            padding: 20px;
            position: absolute;

            .title {
              width: 100%;
              cursor: default;
              @include fontAlt($robotoCondensed, 30px, 34px, 700, uppercase);
              background-color: rgba(0, 128, 255, 0.4);
              padding: 6px 12px;
              color: #fff;
              @include down(sm) {
                font-size: 24px;
              }
            }
          }
        }
      }
    }
  }
}