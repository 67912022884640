.search-bar {
  @include flexRow(space-between);
  width: 100%;
  height: 78px;
  padding: 12px 50px;
  @include up(sm) {
    height: 78px;
    padding: 12px 50px;
  }
  
  @include down(sm) {
    height: 73px;
    padding: 10px 10px 9px;
  }
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 5;
  background: transparent linear-gradient(180deg, #0080FF 0%, #0059B2 100%) 0% 0% no-repeat padding-box;

  .search-bar-content {
    @include flexRow();
    width: 100%;
    height: 100%;
    position: relative;

    .find {
      width: 100%;
      height: 100%;
      color: #000;
      max-height: 40px;
      border: 0;
      @include fontAlt($robotoCondensed, 15px, 20px, 400, uppercase);
      padding-left: 10px;
      @include down(xl) {
        padding: 0px 190px 0px 10px;
        height: 38px;
        margin-bottom: 10px;
        margin-top: 9px;
        border: 0;
      }
      @include up(smd) {
        width: 89vh;
      }
      @include up(md) {
        width: 100vh;
      }
      &::placeholder {
        color: #6a6a6a;
      }

      &:focus {
        outline: none;
      }
    }
  
    .find-overlay {
      @include flexRow(flex-end);
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none;
      .find-now {
        height: calc(100% - 14px);
        margin-left: 10px;
        margin-right: 0px;
        padding: 0px 15px 0px 23px;
        -webkit-clip-path: polygon(15% 0, 100% 0, 100% 100%, 15% 100%, 0% 50%);
        clip-path: polygon(15% 0, 100% 0, 100% 100%, 15% 100%, 0% 50%);
        pointer-events: all;
        @include font($teko, 22px, 500, uppercase);
        background-color: #0080ff;
        color: #fff;
        border: none;
        @include up(lg) {
          margin-right: 40px;
        }
        @include up(sxl) {
          margin-right: 120px;
        }
        &:hover {
          background-color: #0080ff;
          color: #fff;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.search-bar {
  .tt-menu {
    &.tt-open {
      bottom:100%!important;
      top:inherit!important;
      background-color:white;
      z-index: 99;  
      width: 100%;
      text-align: left;
      padding-left: 5px;
      transition: all 0.4s ease;
      padding:5px;
      .tt-selectable {
        color: #000000;
        &:hover {
          background-color: #0080ff;
          color: white;
          padding-left: 10px;
          transition: all 0.4s ease;
        }
        &.tt-cursor {
          background-color: #0080ff;
          color: white!important;
          padding-left: 10px;
          transition: all 0.4s ease;
        }
      }
    }
  }
}