@mixin genericInput() {
  width: 100%;
  height: 40px;

  padding: 0px 8px;

  border: 1px solid #b5b5b5;
  border-radius: 2px;

  @include fontAlt($robotoCondensed, 15px, 18px, 400);

  &::placeholder {
    color: #bfbfbf;
  }
}

@mixin genericInputGroup() {
  width: 100%;
  height: 40px;

  position: relative;

  input {
    width: 100%;

    padding: 0 12px;

    border: 1px solid #b5b5b5;
    border-radius: 2px;

    @include fontAlt($robotoCondensed, 15px, 18px, 400);

    color: #191919;

    &::placeholder {
      color: #bfbfbf;
    }
  }

  .input-overlay {
    @include flexRow(flex-end);

    width: 100%;
    height: 100%;

    position: absolute;
    
    pointer-events: none;

    > * {
      pointer-events: all;
    }

    .controls-wrapper {
      @include flexRow();

      width: 56px;
      height: 100%;

      .fill-arrow-down {
        width: 10px;
        height: 5px;
    
        background-color: #0080ff;
    
        clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
      }
    }
  }
}

@mixin genericSelect() {
  width: 100%;
  height: 40px;

  padding: 0px 12px;

  border: 1px solid #b5b5b5;
  border-radius: 2px;

  @include fontAlt($robotoCondensed, 15px, 18px, 400);

  background-color: #fff;
  color: #191919;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background-image: url('../img/menu-down.svg');
  background-repeat: no-repeat;
  background-position: right 12px top 50%;

  &::placeholder {
    color: #bfbfbf;
  }

  &::-ms-expand {
    display: none;
  }
}

@mixin genericSelectGroup() {
  width: 100%;
  max-width: 100%;
  height: 40px;

  position: relative;

  select {
    width: 100%;
    height: 100%;

    padding: 0 12px;

    border: 1px solid #b5b5b5;
    border-radius: 2px;

    @include fontAlt($robotoCondensed, 15px, 18px, 400);

    background-color: #fff;
    color: #191919;

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    background-image: url('../img/menu-down.svg');
    background-repeat: no-repeat;
    background-position: right 12px top 50%;

    &::placeholder {
      color: #bfbfbf;
    }

    &::-ms-expand {
      display: none;
    }

    &:focus {
      /* outline: none; */
    }
  }
}

.select-group {
  position: relative;
  .country-select-input,
  .question-country-results {
      width: 100%;
      height: 100%;
      padding: 0 12px;
      border: 1px solid #b5b5b5;
      border-radius: 2px;
      @include fontAlt($robotoCondensed, 15px, 18px, 400);
      background-color: #fff;
      color: #191919;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url('../img/menu-down.svg');
      background-repeat: no-repeat;
      background-position: right 12px top 50%;
      text-indent: 1px;
      text-overflow: '';
      &::placeholder {
        color: #bfbfbf;
      }
  
      &:focus {
         outline: none;
      }
  }
  input::-webkit-calendar-picker-indicator {
    display: none;
  }
}