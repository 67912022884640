/* Top level layout */
.generic-content {
  width: 100%;

  @include up(md) {
    padding: 0 50px 0 50px;
  }

  @include up(hd) {
    @include flexRow(center, flex-start);
    @include horizontalMargins(40px / 2);

    .main-bar {
      width: 100%;
    }

    .side-bar {
      flex-shrink: 0;
      @include flexColumn(flex-start);

      width: 305px;
      height: 100%;
    }
  }

  @include down(shd) {
    @include flexColumn();

    .main-bar {
      width: 100%;
    }

    .side-bar {
      @include flexColumn(flex-start);

      width: 100%;
    }
  }

  @include down(md) {
    .side-bar {
      padding: 0 19px;
    }
  }
}

/* Frequently used layouts */
@mixin largeBlueButton() {
  @include flexRow();

  height: 43px;

  padding: 0 27px 0 20px;

  border: none;

  background-color: #0080ff;
  color: #fff;

  clip-path: polygon(0% 0%, calc(100% - 14px) 0%, 100% 50%, calc(100% - 14px) 100%, 0% 100%);

  span {
    margin-top: 2px;

    @include fontAlt($teko, 25px, 36px, 500, uppercase);
  }
}

@mixin smallArrowButton($color: #0080ff, $backgroundColor: #fff) {
  @include flexRow();

  background-color: $backgroundColor;

  clip-path: polygon(0% 0%, calc(100% - 6px) 0%, 100% 50%, calc(100% - 6px) 100%, 0% 100%);

  a {
    padding: 1px 12px 0 6px;

    @include flexRow();
  }

  span {
    @include font($roboto, 12px, 700, uppercase);

    color: $color;
  }
}

@mixin horizontalTabList() {
  @include flexRow(space-between, center);
  @include horizontalMargins(16px / 2);

  @include down(md) {
    margin-left: 18px;
    margin-right: 18px;
  }

  overflow-x: auto;

  .tab {
    flex-grow: 10;
    flex-shrink: 0;

    @include fontAlt($teko, 20px, 29px, 700, uppercase);

    text-align: center;

    span {
      display: inline-block;

      border-bottom: 4px solid transparent;

      color: #a5a5a5;

      cursor: pointer;
      user-select: none;
    }

    &.selected {
      span {
        border-bottom-color: #0080ff;

        color: #333333;
      }
    }
  }
}

@mixin transform($t...){
  -webkit-transform: $t;
  -moz-transform: $t;
  -o-transform: $t;
  -ms-transform: $t;
  transform: $t;
}

@mixin transition($t...){
  -webkit-transition: $t;
  -moz-transition: $t;
  -o-transition: $t;
  -ms-transition: $t;
  transition: $t;
}


@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}