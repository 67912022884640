.country-details-section {
  width: 100%;

  overflow: hidden;

  .site-path-indicator {
    @include up(md) {
      margin: 43px 0 27px;
    }

    @include down(md) {
      margin: 43px 21px 27px;
    }
  }

  .country-details-content {
    @include flexColumn(center, stretch);
    @include verticalMargins(48px / 2);

    margin-bottom: 60px;

    div {
      .title-simple {
        margin-bottom: 18px;
    
        @include fontAlt($robotoCondensed, 25px, 29px, 400, uppercase);
    
        color: #333333;
      }
    }

    .country-info {
      padding: 24px 18px 30px;

      background-color: #f5f5f5;

      @include down(md) {
        margin-left: 17px;
        margin-right: 17px;
      }

      .country-info-header {
        @include flexRow(space-between);

        width: 100%;

        margin-bottom: 20px;

        .name {
          @include fontAlt($teko, 30px, 43px, 500, uppercase);

          color: #333333;
        }

        img {
          width: 50px;
          height: 33px;
        }
      }

      .country-info-body {
        width: 100%;

        @include fontAlt($robotoCondensed, 15px, 24px, 400);

        color: #191919;
      }
    }

    .medal-overview {
      @include down(md) {
        margin-left: 17px;
        margin-right: 17px;
      }

      .medals-md {
        @include genericMedalsOverviewMd();
      }

      .medals-sm {
        @include genericMedalsOverviewSm();
      }
    }

    .featured {
      @include genericVideoListSectionWithHeader();

      @include down(md) {
        margin-left: 17px;
        margin-right: 17px;
      }
    }

    .latest-videos {
      @include genericVideoListSectionWithHeader();

      @include down(md) {
        margin-left: 17px;
        margin-right: 17px;
      }
    }

    .last-results {
      .title-simple {
        @include down(md) {
          margin-left: 28px;
          margin-right: 28px;
        }
      }

      .results-table-lg {
        @include up(lg) {
          @include tableWithBlueHeader();

          .nr, .date {
            width: 1px;
            white-space: nowrap;
          }
        }

        @include down(lg) {
          display: none;
        }
      }

      .results-tables-sm {
        @include up(lg) {
          display: none;
        }

        @include down(lg) {
          @include tablesWithBlueHeaderSm(104px);
        }
      }
    }

    .birthdays {
      .title-simple {
        @include down(md) {
          margin-left: 28px;
          margin-right: 28px;
        }
      }

      .birthdays-table-lg {
        @include up(lg) {
          @include tableWithBlueHeader();

          .nr, .age {
            width: 1px;
            white-space: nowrap;
          }
        }

        @include down(lg) {
          display: none;
        }
      }

      .birthdays-tables-sm {
        @include up(lg) {
          display: none;
        }

        @include down(lg) {
          @include tablesWithBlueHeaderSm();
        }
      }
    }
  }
}