.about-section {
    overflow: hidden;
    width: 100%;
    .site-path-indicator {
      @include up(md) {
        margin: 43px 0 27px;
      }
  
      @include down(md) {
        margin: 43px 21px 27px;
      }
    }
  
    .about {
      @include up(md) {
        margin: 0px 50px;
      }
      @include down(md) {
        margin: 20px;
      }
      .about-header {
        width: 100%;
        margin: 48px 0 38px;
        background-color: transparent;
        color: #0080ff;
        border-bottom: 1px solid #0080ff;
        @include down(lg) {
          text-align: center;
        }
  
        span {
          @include font($robotoCondensed, 30px, 700, uppercase);
  
        }
      }
      .about-content {
        margin-bottom: 30px;
        @include verticalMargins(32px / 2);
        &.thumbnail-content {
            display: flex;
            @include down(sm) {
                flex-direction: column;
                img {
                    max-height: 460px;
                    width: 100%;
                }
            }
            .content-line {
                padding-left: 25px;
                margin-top: 0;
                @include down(sm) {
                    padding-left: 0;
                }
            }
        }
        .content-line {
            color: #191919;
            @include fontAlt($robotoCondensed, 15px, 20px, 400);
            a {
                color: #0080ff;
            }
        }
      }
    }
  }