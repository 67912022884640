.event-details-tab-content.judo-results {
  .results-controls {
    @include flexRow();

    margin-bottom: 57px;

    .country {
      @include genericSelectGroup();
      margin: auto;
      max-width: 277px;
    }
  }  

  .results-wrapper {
    @include responsiveGridCol2($columnGap: 75px);

    .results-men-wrapper, .results-women-wrapper {    
      .results-header {
        @include flexRow();

        height: 60px;

        padding-top: 4px;

        @include fontAlt($teko, 28px, 25px, 400);

        background-color: #0080ff;
        color: #fff;
      }

      .results-body {
        @include flexColumn();
        @include verticalMargins(30px / 2);

        width: 100%;

        .results-table {
          width: 100%;

          tr {
            @include up(md) {
              @include horizontalPaddings(10px, true, true);
            }

            @include down(md) {
              @include horizontalPaddings(5px);
            }

            &.head {
              height: 52px;

              td {
                padding-top: 8px;
              }
            }
            
            &.body {
              height: 39px;
            }

            td {
              border-bottom: 1px solid #b6dbff;
    
              @include fontAlt($robotoCondensed, 19px, 27px, 400);
    
              color: #191919;

              &.place , &.noc {
                width: 1px;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}