/* Layouting */

/* Paddings */

@mixin horizontalPaddings($padding, $first: false, $last: false) {
  > * {
    padding-left: $padding;
    padding-right: $padding;

    @if not ($first) {
      &:first-child {
        padding-left: 0px;
      }
    }
    
    @if not ($last) {
      &:last-child {
        padding-right: 0px;
      }
    }
  }
}

@mixin verticalPaddings($padding, $first: false, $last: false) {
  > * {
    padding-top: $padding;
    padding-bottom: $padding;

    @if not ($first) {
      &:first-child {
        padding-top: 0px;
      }
    }

    @if not ($last) {
      &:last-child {
        padding-bottom: 0px;
      }
    }
  }
}

/* Margins */

@mixin horizontalMargins($margin, $first: 0px, $last: 0px) {
  > * {
    margin-left: $margin;
    margin-right: $margin;

    &:first-child {
      margin-left: $first;
    }

    &:last-child {
      margin-right: $last;
    }
  }
}

@mixin verticalMargins($margin, $first: 0px, $last: 0px) {
  > * {
    margin-top: $margin;
    margin-bottom: $margin;

    &:first-child {
      margin-top: $first;
    }

    &:last-child {
      margin-bottom: $last;
    }
  }
}

/* Flex-es */

@mixin flexRow($justifyContent: center, $alignItems: center, $wrap: nowrap, $inline: false) {
  @if ($inline) {
    display: inline-flex;
  } @else {
    display: flex;
  }
  flex-flow: row $wrap;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin flexRowReverse($justifyContent: center, $alignItems: center, $wrap: nowrap, $inline: false) {
  @if ($inline) {
    display: inline-flex;
  } @else {
    display: flex;
  }
  flex-flow: row-reverse $wrap;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin flexColumn($justifyContent: center, $alignItems: center, $wrap: nowrap, $inline: false) {
  @if ($inline) {
    display: inline-flex;
  } @else {
    display: flex;
  }
  flex-flow: column $wrap;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin flexColumnReverse($justifyContent: center, $alignItems: center, $wrap: nowrap, $inline: false) {
  @if ($inline) {
    display: inline-flex;
  } @else {
    display: flex;
  }
  flex-flow: column-reverse $wrap;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

/* Responsive grids */

@mixin responsiveGridCol2($rowGap: 24px, $columnGap: 40px, $breakpoint1: md) {
  display: grid;
  row-gap: $rowGap;
  column-gap: $columnGap;

  @include up($breakpoint1) {
    grid-template-columns: 1fr 1fr;
  }

  @include down($breakpoint1) {
    grid-template-columns: 1fr;
  }
}

@mixin responsiveGridCol3($rowGap: 40px, $columnGap: 40px, $breakpoint1: md, $breakpoint2: xl) {
  display: grid;
  row-gap: $rowGap;
  column-gap: $columnGap;
  
  @include up($breakpoint2) {  
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include betw($breakpoint1, $breakpoint2) {
    grid-template-columns: 1fr 1fr;
  }

  @include down($breakpoint1) {
    grid-template-columns: 1fr;
  }
}

@mixin responsiveGridCol4 {
  display: grid;

  @include up(xl) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 22px;
    row-gap: 17px;
  }

  @include betw(md, xl) {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
    row-gap: 12px;
  }

  @include down(md) {
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 8px;
  }
}

@mixin responsiveGridCol5 {
  display: grid;
  gap: 16px;
  
  @include up(xl) {  
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @include betw(lg, xl) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include betw(md, lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include betw(sm, md) {
    grid-template-columns: 1fr 1fr;
  }

  @include down(sm) {
    grid-template-columns: 1fr;
  }
}

/* Aspect ratios */

/* Intended for landscape orientation */
@mixin aspectRatioContainer($width: 100px, $height: 100px) {
  height: 0;
  padding-top: $height / $width * 100%;

  position: relative;

  overflow: hidden;
  
  background: white;
}

@mixin aspectRatioContent() {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
}